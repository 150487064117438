import { useTranslation } from 'react-i18next';
import { useState, useEffect } from 'react';
import tokensHelpers from '../../variables/tokens';
const { decodeToken } = tokensHelpers;

export function useIdiomaUsuario() {
    const { i18n } = useTranslation();
    const [idioma, setIdioma] = useState("es");

    useEffect(() => {
        let token;

        try {
            token = localStorage.getItem('token');
        } catch (error) {
            console.error("No hay token disponible!");
            return;
        }

        if (!token) {
            console.error("Falta token!");
            return;
        }

        const claims = decodeToken(token);
        
        if (claims.idioma) {
            console.log(`Idioma detectado: ${claims.idioma}`);
            setIdioma(claims.idioma);
            i18n.changeLanguage(claims.idioma);
        }
    }, []);

    return idioma;
}
