import React from "react";
import Velocimetro from "../../src/components/Puntos/Velocimetro.jsx";
import Grid from "../../src/components/Canvas/canvasdigital.jsx";
import { Row, Col, Card, CardBody } from "reactstrap";
import img from "../assets/img/fondo3.png";
import SprintTimeLine from "../../src/components/Sprints/SprintTimeLine.js";
import ConcentricClickableSectors from "../../src/components/Sprints/EcosystemCircle.js";
import ConcentricSectorsDisplay from "../../src/components/Sprints/EcosystemFinal.js";
import ChartDIM from "../components/Charts/PieDIM.jsx";
import BarChartComponent from "../components/Charts/barsusten.jsx";
import LargeInputBoxes from "../../src/components/Sprints/EcosystemInputs.js";
import EcosystemCanvas from "../../src/components/Sprints/EcosystemCanvas.js";
import Customersprint from "../components/Sprints/sprintcustomer.jsx";
import Test from "../components/Sprints/sprinttest.jsx";
import QuizzPrize from "../components/Sprints/QuizzPrize.jsx";
import Roadmap from "../components/Sprints/sprintroadmap.jsx";
import Bot from "../components/Bot/ttos.js";
import ImageGallery from "../components/Cartas/image.jsx";
import QuizComponent from "../components/Sprints/SprintEscalabilidad.jsx";

const VelocimetroPuntos = () => {
    const valorVelocimetro = 3000;
    const sprints = ['Sprint 0', 'Sprint 1', 'Sprint 2', 'Sprint 3', 'Sprint 4'];
    const currentSprint = 'Sprint 2';
    const currentScore = 7;

    const dataSets = [
        { title: 'BMI', data: { 'Trends': 100, 'Sustainability': 150, 'Patterns': 75, 'BlueOcean': 100 } },
        { title: 'CUSTOMER EXPERIENCE', data: { 'DigitalDrivers': 200, 'Test': 50, 'Platforms': 150, 'CustomerJourney': 150 } },
        { title: 'DIGITAL TRANSFORMATION', data: { 'Ecosystem': 300, 'Roadmap': 100, 'Metrics': 200, 'DataDriven': 200 } },
    ];

    const dataValues = [20, 30, 40];

    const DataCircles = {
        small: [0, 1],
        medium: [2, 3, 4],
        large: [5, 6, 7, 8]
    };

    const images = [
        { front: "/1.png", back: "/1_REV.png" },
        { front: "/2.png", back: "/2_REV.png" },
        { front: "/3.png", back: "/3_REV.png" },
        { front: "/4.png", back: "/4_REV.png" },
        { front: "/5.png", back: "/5_REV.png" },
        { front: "/6.png", back: "/6_REV.png" },
        { front: "/7.png", back: "/7_REV.png" },
        { front: "/8.png", back: "/8_REV.png" },
        { front: "/9.png", back: "/9_REV.png" },
        { front: "/10.png", back: "/10_REV.png" },
        { front: "/11.png", back: "/11_REV.png" },
        { front: "/12.png", back: "/12_REV.png" },
        { front: "/13.png", back: "/13_REV.png" },
        { front: "/14.png", back: "/14_REV.png" }
    ];

    return (
        <div className="content"
            style={{
                backgroundImage: `url(${img})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                height: '100vh',
                width: '100vw',
            }}>
            <Row>
                <Col xs="12">
                    <Card style={{ backgroundColor: '#003366' }}>
                        <CardBody>
                            <Velocimetro value={valorVelocimetro} />
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col xs="12">
                    <Card style={{ backgroundColor: '#003366' }}>
                        <CardBody>
                            <Grid />
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col xs="12">
                    <Card style={{ backgroundColor: '#003366' }}>
                        <CardBody>
                            <h1>Progreso de los Sprints</h1>
                            <SprintTimeLine sprints={sprints} currentSprint={currentSprint} />
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col xs="12" md="8">
                    <Card style={{ backgroundColor: '#003366', padding: "50px" }}>
                        <CardBody>
                            <h1>LIFE AREAS</h1>
                            <ConcentricClickableSectors />
                        </CardBody>
                    </Card>
                </Col>
                <Col xs="12" md="4">
                    <Card style={{ padding: "50px" }}>
                        <CardBody>
                            <h3>Círculo Externo: Facilitador</h3>
                            <h3>Círculo Medio: Realizador</h3>
                            <h3>Círculo Interno: Orquestador</h3>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col xs="12" md="8">
                    <Card style={{ backgroundColor: '#003366', padding: "50px" }}>
                        <CardBody>
                            <h1>LIFE AREAS</h1>
                            <ConcentricSectorsDisplay selectedSectors={DataCircles} />
                        </CardBody>
                    </Card>
                </Col>
                <Col xs="12" md="4">
                    <Card style={{ padding: "50px" }}>
                        <CardBody>
                            <h3>Círculo Externo: Facilitador</h3>
                            <h3>Círculo Medio: Realizador</h3>
                            <h3>Círculo Interno: Orquestador</h3>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col xs="12">
                    <Card style={{ backgroundColor: '#003366', padding: "50px" }}>
                        <CardBody>
                            <ChartDIM dataSets={dataSets} />
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col xs="12">
                    <Card style={{ backgroundColor: '#003366', padding: "50px" }}>
                        <CardBody>
                            <LargeInputBoxes />
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col xs="12">
                    <Card style={{ backgroundColor: '#003366', padding: "50px" }}>
                        <CardBody>
                            <EcosystemCanvas />
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col xs="12">
                    <Card style={{ backgroundColor: '#003366', padding: "50px" }}>
                        <CardBody>
                            <BarChartComponent dataValues={dataValues} />
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col xs="12">
                    <Card style={{ backgroundColor: '#003366', padding: "50px" }}>
                        <CardBody>
                            <Customersprint />
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col xs="12">
                    <Card style={{ backgroundColor: '#003366', padding: "50px" }}>
                        <CardBody>
                            <Test />
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col xs="12">
                    <Card style={{ backgroundColor: '#003366', padding: "50px" }}>
                        <CardBody>
                            <QuizComponent /> {/* Aquí se reemplaza el componente Roadmap */}
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col xs="12">
                    <Card style={{ backgroundColor: '#003366' }}>
                        <CardBody>
                            <h1>Puntaje en Quizz</h1>
                            <QuizzPrize score={currentScore} />
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col xs="12">
                    <Card style={{ backgroundColor: '#003366' }}>
                        <CardBody>
                            <h1>Roadmap VoiceBot</h1>
                            <Bot />
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            {/* Aquí se añade el componente ImageGallery */}
            <Row>
                <Col xs="12">
                    <Card style={{ backgroundColor: '#003366' }}>
                        <CardBody>
                            <h1>Cartas de juego</h1>
                            <ImageGallery images={images} />
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </div>
    );
}

export default VelocimetroPuntos;