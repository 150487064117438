import React, { useState, useEffect } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { Line, Bar, Doughnut, HorizontalBar, Radar } from "react-chartjs-2";
import img from "../assets/img/fondo3.png"; // Ruta IMAGEN

// reactstrap components
import {
  Button,
  ButtonGroup,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Label,
  FormGroup,
  Input,
  Table,
  Row,
  Col,
  UncontrolledTooltip,
} from "reactstrap";

// internos
import puntosHelpers from "../variables/puntos.js"
import tokenHelpers from "../variables/tokens.js"
import equipoHelpers from "../variables/equipos.js"
import cartasHelpers from "../variables/cartas.js";
import jugadasHelpers from "../variables/jugadas.js"
import sprintHelpers from "../variables/sprints.js"
import canvasHelpers from "../variables/canvas.js"
import resultados from "../variables/resultados.js"
import { host } from "../variables/connection.js"
//const resultados = resultadosHelper.tablaResultados

const decodeToken = tokenHelpers.decodeToken;
const equiposEnPartida = equipoHelpers.equiposEnPartida;
const fetchData = puntosHelpers.fetchData;
const fetchModifier = puntosHelpers.fetchModifier;
const numeroSprintsEnPartida = sprintHelpers.numeroSprintsEnPartida;
const cartasEnMazo = cartasHelpers.cartasEnMazo;
const cartaPorNumero = cartasHelpers.cartaPorNumero;
const cartaPorID = cartasHelpers.cartaPorID;
const variasCartasPorID = cartasHelpers.variasCartasPorID;
const listaMazos = cartasHelpers.listaMazos;
const verJugadasEquipo = jugadasHelpers.verJugadasEquipo;

function Metricas2(props) {
  const f = "CPT Final"

  // conf
  const [idioma, setIdioma] = useState("es")
  const [path, setPath] = useState("../assets/es/cartas/")

  // datos usuario
  const [nombre, setNombre] = useState("");
  const [institucion, setInstitucion] = useState("");
  const [fecha, setFecha] = useState("")

  // general
  const [partidaVirtual, setPartidaVirtual] = useState(false);
  const [sprintsJugados, setSprintsJugados] = useState([])
  const [selectedCanvas, setSelectedCanvas] = useState("canvasPostit")
  const arranqueListo = false

  // cucardones
  const [topTres, setTopTres] = useState(null)
  const [topTresMejora, setTopTresMejora] = useState(null)
  const [topTresPorter, setTopTresPorter] = useState(null)
  const [topTresAmit, setTopTresAmit] = useState(null)
  const [puntosGanados, setPuntosGanados] = useState(null)
  const [mejoraGanada, setMejoraGanada] = useState(null)
  const [cucardonesPuntos, setCucardonesPuntos] = useState(null)
  const [cucardonesQuizzes, setCucardonesQuizzes] = useState(null)
  const [cucardonesMejora, setCucardonesMejora] = useState(null)

  // sprint 0
  const [sliderValues, setSliderValues] = useState([5, 5, 5, 5]); // AMIT
  const [sliderValues2, setSliderValues2] = useState([5, 5, 5, 5, 5]); // PORTER
  const [sliderValuesData, setsliderValuesData] = useState([6, 6, 6, 6, 6]); // DATA MATURITY
  const [valWheel, setValWheel] = useState([5, 5, 5, 5]); // valwheel

  // cartas y jugadas
  const [jugadas, setJugadas] = useState(null);
  const [cartas, setCartas] = useState([]);
  const [images, setImages] = useState([]);
  const [dimData, setDimData] = useState([]); // para chart digital innovation measurement
  const [ecosystem_val, setEcoSystemVal] = useState({}) // ECOSYSTEM
  const [sustainData, setSustainData] = useState({}) // SUSTAINABILITY

  // canvas
  const correctorGlobal = 95 // modificar aca si las banderas se salen de los recuadros
  const [tableroWidth, setTableroWidth] = useState(0);
  const [tableroHeight, setTableroHeight] = useState(0);
  const [tableroWidthZero, setTableroWidthZero] = useState(0);
  const [tableroHeightZero, setTableroHeightZero] = useState(0);
  const [droppedCards, setDroppedCards] = useState({
    1: [],
    2: [],
    3: [],
    4: [],
    5: [],
    6: [],
    7: [],
    8: [],
    9: []
  });

  console.log(`jugadas init ${JSON.stringify(jugadas)} tipo ${typeof jugadas}`)
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(true); // Add loading state
  const [userType, setUserType] = useState("Admin");
  const [partidas, setPartidas] = useState([]);
  const [selectedPartida, setSelectedPartida] = useState(null);
  const [nombreEquipo, setNombreEquipo] = useState('');
  const [equipos, setEquipos] = useState([]);
  const [selectedEquipo, setSelectedEquipo] = useState(null);
  const [datosAño1, setdatosAño1] = useState([]);
  const [datosAño1E, setdatosAño1E] = useState([]);
  const [datosAño2, setdatosAño2] = useState([]);
  const [datosAño2E, setdatosAño2E] = useState([]);
  const [datosAño3, setdatosAño3] = useState([]);
  const [datosAño3E, setdatosAño3E] = useState([]);
  const [datosAñoActual, setdatosAñoActual] = useState([]);
  const [datosAñoActualE, setdatosAñoActualE] = useState([]);
  const [TablaMetricas, setTablaMetricas] = useState([]);
  const [tableData, settableData] = useState([]);

  //////////////
  /*  CONFIG  */
  //////////////

  // devuelve true si se jugo el sprint y falso si no
  function jugamosEsteSprint(keyword, data) {
    return data.some(item => item.nombre === keyword);
  }

  async function setearConfDesdeToken() {
    // recibir token de local storage
    const token = localStorage.getItem('token')
    console.log(`jugadas arranque setearConf ${JSON.stringify(jugadas)} tipo ${typeof jugadas}`)

    if (!token) { console.error(`no hay token!`); return null }
    console.log('Token found:', token);
    const claims = decodeToken(token);
    console.log('Token claims:', claims);

    // armar fecha
    const date = new Date();
    var now = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
    setFecha(now);
    console.log(`fecha es ${now}`);

    // armar idioma
    if (claims.idioma) {
      setIdioma(claims.idioma)
      console.log(`seteando path a ../assets/${claims.idioma}/cartas/...`)
      setPath(`../assets/${claims.idioma}/cartas/`)
      i18n.changeLanguage(claims.idioma)

      // setear fecha como string i18n
      now = date.toLocaleDateString(claims.idioma, {
        day: '2-digit',
        month: 'long',
        year: 'numeric',
      });
    }

    // setear datos usuario
    if (claims.nombre && claims.institucion) {
      setNombre(claims.nombre)
      setInstitucion(claims.institucion)
      console.log(`Generando final para ${claims.nombre}, ${claims.institucion}, el dia ${fecha}`)
    }

    // configuracion de juego
    if (claims.role === "Player" || claims.role === "Captain") {

      // setear partida y equipo
      setSelectedPartida(claims.partida)
      setSelectedEquipo(claims.equipo)
      console.log(`Seteando pagina final para partida ${claims.partida}, equipo ${claims.equipo}...`)

      // datos asociados al equipo
      var datosEquipos, datosEquipo, datosSprints, datosJugadas, datosMejora
      try {
        datosEquipos = await equiposEnPartida(claims.partida)
        datosEquipo = await fetchData(claims.partida, claims.equipo)
        datosSprints = await numeroSprintsEnPartida(claims.partida)
        datosJugadas = await verJugadasEquipo(claims.partida, claims.equipo)

        const mejora = {
          total: Math.round((datosMejora.mejora_total - 1) * 1000, 0),
          porter: Math.round((datosMejora.mejora_porter) * 1000, 0), // agregamos un 0 hasta determinar pq es distinto del grafico
          amit: Math.round((datosMejora.mejora_amit) * 1000, 0)
        }
        setMejoraGanada(mejora)

        //console.log(`datos equipo son ${JSON.stringify(datosEquipo)}`)
        console.log(`Equipo esta en sprint id ${datosEquipo[0]["sprint_id"]}`)
        console.log(`datos de todos los sprints en p son ${JSON.stringify(datosSprints)}`)
        console.log(`datos de todos las jugadas son ${JSON.stringify(datosJugadas)}`)
        console.log(`datos mejora son ${JSON.stringify(mejora)}`)

        // armar un array de nombres de sprints jugados
        const datosSprintsJugados = [...new Set(datosSprints.map(item => item.nombre))];
        setSprintsJugados(datosSprintsJugados);

        // ir alimentando a cada seccion
        console.log(`enviando a top3: ${JSON.stringify(datosEquipos)}`)

        traerPuntosEquipo(datosEquipo[0]) // sprint 0
        // metricas se arma solo
        // blue ocean? lo encuentra

        // ecosystem
        var ecosystem
        setEcoSystemVal(ecosystem)

        // empezar a armar jugadas, DIM chart, Sustainability
        setJugadas(datosJugadas) // traer cartas jugadas

        console.log(`jugadas seteado ${JSON.stringify(jugadas)} tipo ${typeof jugadas}`)

        if (claims.presencial === 1) {
          // JUEGO PRESENCIAL


        } else {
          // JUEGO VIRTUAL
          setPartidaVirtual(true)

          // traer el framework basado en partida

          // setear img framework
          // traer recuadros canvas del framework


          // convierte a los recuadros en un array de IDs
          var recuadrosViejos = {}

          // si se jugo data driven virt, agregar recuadros de ese
          if (datosSprintsJugados.includes("DataDriven")) {

          }

          // filtrar jugadas para los que tengan recuadro
          if (datosJugadas) {
            datosJugadas.filter(x => x.recuadro)
            console.log(`jugadas anteriores con recuadro son ${JSON.stringify(datosJugadas)}`)

            // traer todas las imagenes para las cartas jugadas previamente
            const arrayCartasViejas = datosJugadas.map(x => x.carta_id)
            console.log(`arrayCartasViejas es ${arrayCartasViejas}`)
            const cartasViejasData = await variasCartasPorID(arrayCartasViejas)
            console.log(`cartasViejasData es ${JSON.stringify(cartasViejasData)}`)


            // armar las imagenes de los sprints anteriores y ponerlos en el tablero
            // mapear los recuadros, sin que se puedan deseleccionar

          }

          //console.log(`jugD nuevo es ${JSON.stringify(jugD)}`);
        }

        console.log(`recuadrosViejos son ${JSON.stringify(recuadrosViejos)}`);
        setDroppedCards(recuadrosViejos);
      } catch (error) {
        console.error(error);
      }

    } else {
      // si no hay jugadas anteriores, monta un array de objetos con arrays vacios
    }
    setDroppedCards(recuadrosViejos)
  }

  useEffect(() => {
    //console.log(`jugadas antes useEffect ${JSON.stringify(jugadas)} tipo ${typeof jugadas}`)
    setearConfDesdeToken()
  }, [])

  function setearConfDesdeToken() {
    // recibir token de local storage
    const token = localStorage.getItem('token')

    if (token) {
      console.log('Token found:', token);
      const claims = decodeToken(token);
      console.log('Token claims:', claims);

      // armar idioma
      if (claims.idioma) { i18n.changeLanguage(claims.idioma) }

      // configuracion de juego
      if (claims.role === "Player" || claims.role === "Captain") {
        setUserType("Player")
        setSelectedPartida(claims.partida)
        setSelectedEquipo(claims.equipo)
      } else if (claims.role === "Admin") {
        setUserType("Admin")
      } else {
        console.error("y vos quien sos ???")
      }
    }
  }
  
  //////////////
  /* SPRINT 0 */
  //////////////

  // buscar puntos del equipo
  async function traerPuntosEquipo(equipo) {
    //console.log(`datos equipo: ${JSON.stringify(equipo)}, tipo ${typeof equipo}`)

    //console.log(`Porter antes de cambiar: ${sliderValues2}`)
    //console.log(`Amit antes de cambiar: ${sliderValues}`)

    //console.log(`keys equipo: ${Object.keys(equipo)}`)

    //console.log(`porter buyers orig: ${equipo.porter_buyers_orig}, tipo ${typeof equipo.porter_buyers_orig}`)
    //console.log(`como numero: ${Number(equipo.porter_buyers_orig)}, tipo ${typeof Number(equipo.porter_buyers_orig)}`)

    // este clamp se asegura que los valores esten dentro del rango aceptable para los charts
    const clampValue = (value, min, max) => {
      return Math.max(min, Math.min(max, value));
    }

    const porter = [
      clampValue(Number(equipo.porter_buyers_mod) || 5, 1, 7),
      clampValue(Number(equipo.porter_suppliers_mod) || 5, 1, 7),
      clampValue(Number(equipo.porter_rivalry_mod) || 5, 1, 7),
      clampValue(Number(equipo.porter_entrants_mod) || 5, 1, 7),
      clampValue(Number(equipo.porter_substitutes_mod) || 5, 1, 7)
    ]

    const amit = [
      clampValue(Number(equipo.driver_novelty_mod) || 5, 1, 7),
      clampValue(Number(equipo.driver_lockin_mod) || 5, 1, 7),
      clampValue(Number(equipo.driver_complementary_mod) || 5, 1, 7),
      clampValue(Number(equipo.driver_efficiency_mod) || 5, 1, 7)
    ]

    const dataMaturity = [
      clampValue(Number(equipo.datamaturity_estrategia_mod) || 5, 1, 7),
      clampValue(Number(equipo.datamaturity_cultura_mod) || 5, 1, 7),
      clampValue(Number(equipo.datamaturity_orgestructura_mod) || 5, 1, 7),
      clampValue(Number(equipo.datamaturity_tecnologia_mod) || 5, 1, 7),
      clampValue(Number(equipo.datamaturity_centralidad_mod) || 5, 1, 7)
    ]

    //	labels: ["What", "How", "Why", "Who"],

    //console.log(`porter: ${porter}`)
    //console.log(`amit: ${amit}`)

    // setear sliders
    setSliderValues2(prevValues => porter)
    setSliderValues(prevValues => amit)
    setsliderValuesData(prevValues => dataMaturity)

  }

  // porter
  const data = {
    labels: ["Novelty", "Lock-In", "Complementarities", "Efficiency"],
    datasets: [
      {
        label: t('amit.valores'),
        backgroundColor: "rgba(54, 162, 235, 0.2)",
        borderColor: "rgba(54, 162, 235, 0.2)",
        borderWidth: 1,
        data: sliderValues,
      },
    ],
  };

  // amit
  const data2 = { // Datos para el segundo gráfico
    labels: ["Buyers", "Suppliers", "Competitors", "New Entry", "Substitutes"],
    datasets: [
      {
        label: t('porter.valores'),
        backgroundColor: "rgba(54, 162, 235, 0.2)",
        borderColor: "rgba(54, 162, 235, 0.2)",
        borderWidth: 1,
        data: sliderValues2,
      },
    ],
  };

  // madurez
  const dataDataMaturity = { // Datos para DATA MATURITY
    labels: ["Strategy", "Culture", "Organization structure", "Technology", "Customer Centricity"],
    datasets: [
      {
        label: t('Data_Maturity.valores'),
        backgroundColor: "rgba(54, 162, 235, 0.2)",
        borderColor: "rgba(54, 162, 235, 0.2)",
        borderWidth: 1,
        data: sliderValuesData,
      },
    ],
  };

  const radarOptions = {
    scale: {
      pointLabels: {
        fontColor: '#000', // Color de las etiquetas, ajusta según necesites
        font: {
          size: 12, // Tamaño de la fuente, ajusta según necesites
          weight: 'bold' // Etiquetas en negrita
        }
      },
      gridLines: {
        color: 'rgba(0, 0, 0, 0.1)', // Color de las líneas de la cuadrícula, ajusta la opacidad según necesites
      },
      ticks: {
        backdropColor: 'rgba(0, 0, 0, 0)', // Color de fondo de las marcas
      },
      min: 1,
      max: 7,
      stepSize: 1,
    },
  };

  const findPartidaName = () => {
    const partida = partidas.find(p => p.id === selectedPartida);
    return partida ? partida.nombre : "Selecciona una Partida";
  };

  const findEquipoName = () => {
    const equipo = equipos.find(e => e.id === selectedEquipo);
    return equipo ? equipo.nombre : "Selecciona un Equipo";
  };

  const fetchPartidas = async () => {
    try {
      const response = await fetch(`https://${host}/api/partidas`);
      if (!response.ok) { throw new Error('Respuesta del servidor no es OK'); }
      const data = await response.json();
      if (Array.isArray(data.data) && data.data.length) {
        setPartidas(data.data);
      } else {
        console.error("Formato de datos no esperado:", data);
      }
    } catch (error) {
      console.error("Error al obtener los datos:", error);
    }
  };

  const fetchEquiposPorPartida = async (partidaId) => {
    try {
      const response = await fetch(`https://${host}/juego/${partidaId}`);
      if (!response.ok) { throw new Error('Respuesta del servidor no es OK'); }
      const data = await response.json();
      if (Array.isArray(data.data)) {
        setEquipos(data.data);
      } else {
        setEquipos([]);
        console.warn(data.message);
      }
    } catch (error) {
      setEquipos([]); // En caso de un error, resetea los equipos.
      console.error("Error al obtener los equipos:", error);
    }
  };

  useEffect(() => {
    fetchPartidas();
  }, []);

  // Este useEffect se encargará de cargar los equipos cuando cambie la partida seleccionada
  useEffect(() => {
    if (selectedPartida) {
      fetchEquiposPorPartida(selectedPartida);
    }
  }, [selectedPartida]);

  const handlePartidaChange = (e) => {
    const partidaId = e.target.value;
    setSelectedPartida(partidaId);
    setEquipos([]);  // Resetea los equipos cuando cambias la partida
    setSelectedEquipo(null);  // Resetea el equipo seleccionado
  };

  const handleEquipoChange = (e) => {
    const equipoId = e.target.value;
    setSelectedEquipo(equipoId);
  };

  // Use useEffect to fetch resultados data and set loading state
  useEffect(() => {
    resultados.tablaResultados(selectedPartida, selectedEquipo)
      .then((result) => {
        console.log("Resultados traidos exitosamente");
        var datosResultados = result;
        console.log(JSON.stringify(datosResultados))
        setdatosAño1(result.datosAño1);
        console.log(`Año 1: ${JSON.stringify(datosAño1)}`)
        setdatosAño1E(result.datosAño1E);
        setdatosAño2(result.datosAño2);
        setdatosAño2E(result.datosAño2E);
        setdatosAño3(result.datosAño3);
        setdatosAño3E(result.datosAño3E);
        setdatosAñoActual(result.datosAñoActual);
        setdatosAñoActualE(result.datosAñoActualE);
        setTablaMetricas(result.TablaMetricas);
        settableData(result.tableData);
        setLoading(false); // Set loading to false once data is available
      })
      .catch((error) => {
        console.error("Error trayendo resultados:", error);
        setLoading(true); // Set loading to false in case of an error
      });
  }, [selectedPartida, selectedEquipo]);

  const getPercentageAsInteger = (percentageString) => {
    return parseInt(percentageString.replace('%', ''), 10);
  }

  const deliveryReliabilityValue = TablaMetricas && TablaMetricas[1] && TablaMetricas[1][2] ? getPercentageAsInteger(TablaMetricas[1][2]) : "0";

  const deliveryData = {
    labels: ["Proyectado", "Final"],
    datasets: [
      {
        label: "Delivery Reliability",
        data: [90, deliveryReliabilityValue],
        backgroundColor: ["#36a2eb", "#ffcd56"],
        borderColor: ["#36a2eb", "#ffcd56"],
        borderWidth: 1,
      },
    ],
  };

  // Convertir los valores de TablaMetricas a enteros
  const customerEffortValue = TablaMetricas && TablaMetricas[3] ? (getPercentageAsInteger(TablaMetricas[3][2]) || 0) : "0";
  const customerSatisfactionValue = TablaMetricas && TablaMetricas[4] ? (getPercentageAsInteger(TablaMetricas[4][2]) || 0) : "0";
  const customerRetentionValue = TablaMetricas && TablaMetricas[5] ? (getPercentageAsInteger(TablaMetricas[5][2]) || 0) : "0";

  const deliveryOptions = {
    scales: {
      y: {
        beginAtZero: true,
        max: 100,
        ticks: {
          color: 'black', // Cambia el color de los textos en el eje Y
        },
      },
      x: {
        ticks: {
          color: 'black', // Cambia el color de los textos en el eje Y
        },
      },
    },
    plugins: {
      legend: {
        labels: {
          color: 'black', // Cambia el color de las etiquetas de la leyenda
        },
      },
    },
  };

  const ventasOptions = {
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          color: 'black', // Cambia el color de los textos en el eje Y
        },
      },
      x: {
        ticks: {
          color: 'black', // Cambia el color de los textos en el eje Y
        },
      },
    },
    plugins: {
      legend: {
        labels: {
          color: 'black', // Cambia el color de las etiquetas de la leyenda
        },
      },
    },
  };

  const customerOptions = {
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          color: 'black', // Cambia el color de los textos en el eje Y
        },
      },
      x: {
        ticks: {
          color: 'black', // Cambia el color de los textos en el eje Y
        },
      },
    },
    plugins: {
      legend: {
        labels: {
          color: 'black', // Cambia el color de las etiquetas de la leyenda
        },
      },
    },
  };

  const ventasArticuloOptions = {
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          color: 'black', // Cambia el color de los textos en el eje Y
        },
      },
      x: {
        ticks: {
          color: 'black', // Cambia el color de los textos en el eje Y
        },
      },
    },
    plugins: {
      legend: {
        labels: {
          color: 'black', // Cambia el color de las etiquetas de la leyenda
        },
      },
    },
  };


  // Convertir el valor de TablaMetricas a entero
  const roiValue = TablaMetricas && TablaMetricas[13] ? (getPercentageAsInteger(TablaMetricas[13][2]) || 0) : "0";

  const ROI = {
    labels: [t('metricas.proyectado'), t('metricas.final')],
    datasets: [
      {
        label: "ROI",
        data: [11.5, roiValue], // Valor final transformado a entero
        backgroundColor: ["#36a2eb", "#ffcd56"],
        borderColor: ["#36a2eb", "#ffcd56"],
        borderWidth: 1,
      },
    ],
  };

  const ROIOptions = {
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          color: 'black', // Cambia el color de los textos en el eje Y
        },
      },
      x: {
        ticks: {
          color: 'black', // Cambia el color de los textos en el eje Y
        },
      },
    },
    plugins: {
      legend: {
        labels: {
          color: 'black', // Cambia el color de las etiquetas de la leyenda
        },
      },
    },
  };

  const doughnutOptions = {
    plugins: {
      legend: {
        labels: {
          color: 'black', // Cambia el color de las etiquetas de la leyenda
        },
      },
    },
  };

  const dataEERR = {
    labels: [t('metricas.cambioEstado'), t('metricas.resNeto')],
    datasets: [
      {
        label: t('metricas.reliability'),
        data: [0, TablaMetricas?.[1]?.[2] ?? 0],
        backgroundColor: ["#36A2EB", "#e0e0e0"],
      },
    ],
  };

  const optionsEERR = {
    scales: {
      xAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
    // Configuración adicional para que sea un gráfico de barra horizontal
    indexAxis: "y", // Esto hace que sea un gráfico de barra horizontal
  };

  const data1Market = 30; // Tu valor proyectado
  const data2Market = 100 - data1Market; // Calculamos el valor restante para llegar al 100%

  // Convertimos el valor en formato string porcentual a número
  const marketShareFinalValue = TablaMetricas && TablaMetricas[19] ? (getPercentageAsInteger(TablaMetricas[19][2]) || 0) : "0";

  // Calculamos los valores para las rosquillas
  const data1MarketF = Math.min(100, marketShareFinalValue);
  const data2MarketF = 100 - data1MarketF;

  const years = [`${t('metricas.year')} 1`, `${t('metricas.year')} 2`, `${t('metricas.year')} 3`, `${t('metricas.year')} 4`]; // Cambia estos valores a tus años reales
  const earnings = [-380000, -30000, 628000, datosAñoActualE?.[20]?.[2] ?? 0]; // Cambia estos valores a tus datos reales
  const cumulativeCashFlow = [-380000, -410000, 218000, (datosAñoActualE?.[20]?.[2] ?? 0) + 218000]; // Cambia estos valores a tus datos reales

  const dataCashFlow = {
    labels: years,
    datasets: [
      {
        label: "Cumulative Cash Flow",
        data: cumulativeCashFlow,
        fill: true,
        backgroundColor: "rgba(54, 162, 235, 0.2)",
        borderColor: "rgba(54, 162, 235, 1)",
        tension: 0.4, // Configura la tensión para suavizar las líneas
      },
      {
        label: "Earnings",
        data: earnings,
        fill: true,
        backgroundColor: "rgba(255, 99, 132, 0.2)",
        borderColor: "rgba(255, 99, 132, 1)",
        tension: 0.4, // Configura la tensión para suavizar las líneas
      },
    ],
  };

  const optionsCashFlow = {
    scales: {
      y: {
        beginAtZero: false, // Permite valores negativos en el eje vertical
      },
    },
  };

  function formatCurrency(value) {
    if (typeof value !== 'number') return value;

    const formatter = new Intl.NumberFormat('es-AR', {
      style: 'currency',
      currency: 'ARS',
      minimumFractionDigits: 0
    });

    return formatter.format(value);
  }

  //  console.log('TablaMetricas[22][0]', TablaMetricas[22][0]);
  //  console.log('datosAñoActualE[20][2]', datosAñoActualE[20][2]);
  function monetaryToNumber(monetaryString) {
    if (typeof monetaryString !== "string") {
      console.error("Valor no es una cadena:", monetaryString);
      return 0;
    }
    let numberString = monetaryString.replace(/\./g, '').replace(',', '.').replace(/\$/g, '');
    return parseFloat(numberString);
  }

  return (
    <>
      <div className="content"
        style={{
          backgroundImage: `url(${img})`, // Aplicar la imagen de fondo importada
          backgroundSize: 'cover', // Opcional, ajusta el tamaño de la imagen de fondo
          backgroundRepeat: 'no-repeat', // Opcional, controla la repetición de la imagen
        }}>
        {userType !== "Player" && userType !== "Captain" ? (
          <Row>
            <Col xs="12">
              <Card style={{ backgroundColor: '#003366' }}>
                <CardBody>
                  <h2 style={{ color: '#E8E8E8' }}>Seleccionar partida & equipo</h2>
                  <FormGroup style={{ marginBottom: '10px', maxWidth: '50%' }}>
                    <Label style={{ color: '#E8E8E8' }}>Seleccione una Partida</Label>
                    <Input
                      style={{
                        color: '#E8E8E8',
                        borderColor: '#2C83D5',
                        fontSize: '18px',
                        backgroundColor: 'transparent',
                        padding: '10px 15px', // Añadido padding
                        height: 'auto'       // Establecer la altura en auto para ajustarse al contenido
                      }}
                      type="select"
                      value={selectedPartida || ''}
                      onChange={handlePartidaChange}
                    >
                      {partidas.map(partida => (
                        <option key={partida.id} value={partida.id}>
                          {partida.nombre}
                        </option>
                      ))}
                    </Input>
                    <Label style={{ color: '#E8E8E8' }}>Seleccione un Equipo</Label>
                    <Input
                      style={{
                        color: '#E8E8E8',
                        borderColor: '#2C83D5',
                        fontSize: '18px',
                        backgroundColor: 'transparent',
                        padding: '10px 15px', // Añadido padding
                        height: 'auto'       // Establecer la altura en auto para ajustarse al contenido
                      }}
                      type="select"
                      value={selectedEquipo || ''}
                      onChange={handleEquipoChange}
                    >
                      {equipos.map(equipo => (
                        <option key={equipo.id} value={equipo.id}>
                          {equipo.nombre}
                        </option>
                      ))}
                    </Input>
                  </FormGroup>
                </CardBody>
              </Card>
            </Col>
          </Row>
        ) : null}

        {/* ESPERAR A QUE CARGUEN LOS DATOS ANTES DE RENDER */}
        {loading ? (
          <p>{t('common.cargandoResultados')}...</p>
        ) : (
          // Render your tables and charts when loading is false
          <>
            <Row>
              <Col lg="4">
                <Card className="card-chart" style={{ backgroundColor: '#FAFAFA' }}>
                  <CardHeader style={{ textAlign: 'center' }}>
                    <h1 className="card-category" style={{ fontSize: '24px', color: '#005F73' }}>{TablaMetricas[1][2]}</h1>
                    <CardTitle tag="h3" style={{ color: '#000000', fontWeight: '550' }}>{t('metricas.reliability')}</CardTitle>
                  </CardHeader>
                  <CardBody>
                    <div className="chart-area">
                      <Bar data={deliveryData} options={deliveryOptions} />
                    </div>
                    <h5 style={{ color: '#000000', fontWeight: '550' }}>
                      {t('metricas.reliabilityDesc')}
                    </h5>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="4">
                <Card className="card-chart" style={{ backgroundColor: '#FAFAFA' }}>
                  <CardHeader style={{ textAlign: 'center' }}>
                    <h1 className="card-category" style={{ fontSize: '24px', color: '#005F73' }}>{t('metricas.itemsVendidos')}</h1>
                    <CardTitle tag="h3" style={{ color: '#000000', fontWeight: '550' }}>{t('metricas.ventasCostos')}</CardTitle>
                  </CardHeader>
                  <CardBody>
                    <div className="chart-area">
                      <Bar
                        data={{
                          labels: ["Item 1", "Item 2", "Item 3", "Item 4", "Item 5"],
                          datasets: [
                            {
                              label: t('metricas.venta'),
                              backgroundColor: "#36A2EB",
                              data: [
                                monetaryToNumber(tableData[1][7]),
                                monetaryToNumber(tableData[2][7]),
                                monetaryToNumber(tableData[3][7]),
                                monetaryToNumber(tableData[4][7]),
                                monetaryToNumber(tableData[5][7])
                              ],
                            },
                            {
                              label: t('metricas.costo'),
                              backgroundColor: "#FF6384",
                              data: [
                                monetaryToNumber(tableData[1][8]),
                                monetaryToNumber(tableData[2][8]),
                                monetaryToNumber(tableData[3][8]),
                                monetaryToNumber(tableData[4][8]),
                                monetaryToNumber(tableData[5][8])
                              ],
                            },
                          ],
                        }}
                        options={ventasOptions} // Aquí pasamos las opciones como una variable
                      />
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="4">
                <Card className="card-chart" style={{ backgroundColor: '#FAFAFA' }}>
                  <CardHeader style={{ textAlign: 'center' }}>
                    <h1 className="card-category" style={{ fontSize: '24px', color: '#005F73' }}>{t('metricas.customer')}</h1>
                  </CardHeader>
                  <CardBody>
                    <div className="chart-area">
                      <Bar
                        data={{
                          labels: [t('metricas.customerEffort'), t('metricas.customerSatisf'), t('metricas.customerRet')],
                          datasets: [
                            {
                              label: t('metricas.proyectado'),
                              backgroundColor: "#36A2EB", // Color para las barras de venta
                              data: [15, 85, 35], // Valores de ventas
                            },
                            {
                              label: t('metricas.final'),
                              backgroundColor: "#FF6384", // Color para las barras de costo
                              data: [customerEffortValue, customerSatisfactionValue, customerRetentionValue], // Valores de costos transformados
                            },
                          ],
                        }}
                        options={customerOptions} // Aquí pasamos las opciones como una variable
                      />
                    </div>
                    <h5 style={{ fontSize: '16px', color: '#005F73' }}>
                      {t('metricas.customerEffortDesc')}
                      <br></br>
                      {t('metricas.customerSatisfDesc')}
                      <br></br>
                      {t('metricas.customerRetDesc')}
                    </h5>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col lg="4">
                <Card className="card-chart" style={{ backgroundColor: '#FAFAFA' }}>
                  <CardHeader style={{ textAlign: 'center' }}>
                    <h1 className="card-category" style={{ fontSize: '24px', color: '#005F73' }}>{TablaMetricas[13][2]}</h1>
                    <CardTitle tag="h3" style={{ color: '#000000', fontWeight: '550' }}>{t('metricas.ROI')}</CardTitle>
                  </CardHeader>
                  <CardBody>
                    <div className="chart-area">
                      <Bar data={ROI} options={ROIOptions} />
                    </div>
                    <h5 style={{ fontSize: '16px', color: '#005F73' }}>
                      {t('metricas.ROIDesc')}
                    </h5>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="4">
                <Card className="card-chart" style={{ backgroundColor: '#FAFAFA' }}>
                  <CardHeader style={{ textAlign: 'center' }}>
                    <h1 className="card-category" style={{ fontSize: '24px', color: '#005F73' }}>{t('metricas.marketShare')}</h1>
                    <CardTitle tag="h3" style={{ color: '#000000', fontWeight: '550' }}>{t('metricas.proyectadoFinal')}</CardTitle>
                  </CardHeader>
                  <CardBody className="d-flex justify-content-center">
                    <div className="chart-area" style={{ width: '150px', height: '150px' }}>
                      <Doughnut
                        data={{
                          labels: [t('metricas.proyectado'), t('metricas.mercadoRestante')], // Etiquetas para los datos
                          datasets: [
                            {
                              data: [data1Market, data2Market], // Usamos los valores calculados
                              backgroundColor: ["#36A2EB", "#e0e0e0"], // Colores de las secciones de la rosquilla
                            },
                          ],
                        }}
                        options={doughnutOptions} // Aplicamos las opciones aquí
                      />
                    </div>
                    <div className="chart-area" style={{ width: '150px', height: '150px' }}>
                      <Doughnut
                        data={{
                          labels: [t('metricas.final'), t('metricas.mercadoRestante')], // Etiquetas para los datos
                          datasets: [
                            {
                              data: [data1MarketF, data2MarketF], // Usamos los valores calculados
                              backgroundColor: ["#36A2EB", "#e0e0e0"], // Colores de las secciones de la rosquilla
                            },
                          ],
                        }}
                        options={doughnutOptions} // Aplicamos las opciones aquí
                      />
                    </div>
                  </CardBody>
                  <h5 style={{ fontSize: '16px', color: '#005F73' }}>
                    {t('metricas.marketShareDesc')}
                  </h5>
                </Card>
              </Col>
              <Col lg="4">
                <Card className="card-chart" style={{ backgroundColor: '#FAFAFA' }}>
                  <CardHeader style={{ textAlign: 'center' }}>
                    <h1 className="card-category" style={{ fontSize: '24px', color: '#005F73' }}>{t('metricas.ventasPorArt')}</h1>
                    <CardTitle tag="h3" style={{ color: '#000000', fontWeight: '550' }}>{t('metricas.ventasActuales')}/{t('metricas.ventasProyectadas')}</CardTitle>
                  </CardHeader>
                  <CardBody>
                    <div className="chart-area">
                      <Bar
                        data={{
                          labels: ["Item 1", "Item 2", "Item 3", "Item 4", "Item 5"],
                          datasets: [
                            {
                              label: t('metricas.ventasActuales'),
                              backgroundColor: "#36A2EB",
                              data: [tableData[1][1], tableData[2][1], tableData[3][1], tableData[4][1], tableData[5][1]], // Corregido el acceso a los datos
                            },
                            {
                              label: t('metricas.ventasProyectadas'),
                              backgroundColor: "#FF6384",
                              data: [3000, 2500, 2000, 1000, 500],
                            },
                          ],
                        }}
                        options={ventasArticuloOptions} // Aquí pasamos las opciones como una variable
                      />
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            {/* PORTER, AMIT, ZOTT */}
            <Row>
              {/* PORTER */}
              <Col xs="6">
                <Card style={{ backgroundColor: '#FAFAFA' }}>
                  <CardBody>
                    <h2 style={{ color: '#2c2c2c' }}>{t('porter.label')}</h2>
                    <div id="radar-chart-container" style={{ textAlign: "center" }}>
                      <div style={{ width: "400px", margin: "0 auto" }}>
                        <Radar data={data2} options={radarOptions} />
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
              {/* AMIT & ZOTT */}
              <Col xs="6">
                <Card style={{ backgroundColor: '#FAFAFA' }}>
                  <CardBody>
                    <h2 style={{ color: '#2c2c2c' }}>{t('amit.label')}</h2>
                    <div id="radar-chart-container" style={{ textAlign: "center" }}>
                      <div style={{ width: "400px", margin: "0 auto" }}>
                        <Radar data={data} options={radarOptions} />
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            {/* DIGITAL MATURITY */}
            {dataDataMaturity ? (
              <Row>
                <Col xs="12">
                  <Card style={{ backgroundColor: '#FAFAFA' }}>
                    <CardBody>
                      <h2 style={{ color: '#005F73' }}>{t('Data_Maturity.label')}</h2>
                      <div id="radar-chart-container" style={{ textAlign: "center" }}>
                        <div style={{ width: "400px", margin: "0 auto" }}>
                          <Radar data={dataDataMaturity} options={radarOptions} />
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            ) : null}
          </>
        )}
      </div>
    </>
  );
}

export default Metricas2;
