import React, { useState } from 'react';

const quizzes = [
  {
    id: 1,
    front: {
      problema: "Competencia feroz de una startup similar",
      escenario:
        "Hemos alcanzado un producto viable y tenemos una base de usuarios significativa, pero ahora enfrentamos una competencia feroz de una startup similar que nos está ganando mercado rápidamente. ¿Cómo deberíamos responder para mantener nuestro liderazgo en el mercado?",
      criterios: [
        "Velocidad sobre Eficiencia",
        "Adaptabilidad y Flexibilidad",
        "Innovación y Diferenciación",
        "Escalabilidad",
        "Minimización del Riesgo a Largo Plazo",
      ],
    },
    back: {
      opciones: [
        "Aumentar significativamente la inversión en marketing y ventas para atraer a más usuarios rápidamente.",
        "Centrarse en mejorar la eficiencia operativa para reducir costos y ofrecer precios más bajos.",
        "Introducir nuevas características innovadoras al producto para diferenciarse de la competencia.",
        "Buscar una alianza estratégica o una adquisición para fortalecer la posición en el mercado.",
      ],
      solucionCorrecta: 3,
      casoEjemplo:
        "Airbnb enfrentó una situación similar cuando Wimdu surgió como competidor en Europa. Optaron por aumentar la inversión en expansión internacional y adquirieron una pequeña empresa alemana para fortalecer su presencia en ese mercado.",
    },
  },
  {
    id: 2,
    front: {
      problema: "Contratación rápida de talento de alta calidad",
      escenario:
        "Experimentamos un rápido crecimiento y necesitamos contratar rápidamente una gran cantidad de empleados, pero a su vez estamos luchando por encontrar y retener talento de alta calidad. ¿Qué estrategia debemos seguir?",
      criterios: [
        "Velocidad sobre Eficiencia",
        "Adaptabilidad y Flexibilidad",
        "Innovación y Diferenciación",
        "Escalabilidad",
        "Minimización del Riesgo a Largo Plazo",
      ],
    },
    back: {
      opciones: [
        "Implementar un programa de referidos incentivado para atraer talento a través de las recomendaciones de los empleados actuales.",
        "Externalizar funciones clave a consultoras especializadas para cubrir la falta de talento interno.",
        "Ofrecer paquetes de compensación altamente competitivos y beneficios adicionales para atraer talento de alta calidad.",
        "Establecer una academia interna para formar rápidamente a los nuevos empleados y promover el talento desde dentro.",
      ],
      solucionCorrecta: 0,
      casoEjemplo:
        "LinkedIn enfrentó una rápida expansión y utilizó un programa de referidos incentivado que resultó en la contratación de talento de alta calidad a través de las recomendaciones de los empleados actuales.",
    },
  },
  {
    id: 3,
    front: {
      problema:
        "Mantener una cultura organizacional coherente en múltiples mercados",
      escenario:
        "Crecemos de manera acelerada y ahora operamos en múltiples mercados internacionales. Sin embargo, estamos teniendo dificultades para mantener una cultura organizacional coherente y alineada. ¿Qué debemos hacer?",
      criterios: [
        "Velocidad sobre Eficiencia",
        "Adaptabilidad y Flexibilidad",
        "Innovación y Diferenciación",
        "Escalabilidad",
        "Minimización del Riesgo a Largo Plazo",
      ],
    },
    back: {
      opciones: [
        "Establecer programas de intercambio de empleados entre oficinas internacionales para fomentar la cohesión.",
        "Crear y promover una visión y misión claras que todos los empleados puedan compartir.",
        "Implementar una plataforma de comunicación interna para mantener a todos los empleados informados y conectados.",
        "Organizar eventos anuales de la empresa para reforzar la cultura y fortalecer las relaciones entre equipos.",
      ],
      solucionCorrecta: 1,
      casoEjemplo:
        "Google ha mantenido una cultura coherente a través de una comunicación clara de su misión y valores, así como programas de intercambio de empleados y eventos corporativos globales.",
    },
  },
];

const QuizComponent = () => {
  const [currentQuiz, setCurrentQuiz] = useState(0);
  const [score, setScore] = useState(0);
  const [selectedOption, setSelectedOption] = useState(null);
  const [showAnswer, setShowAnswer] = useState(false);

  const handleSubmit = () => {
    const quiz = quizzes[currentQuiz];
    if (selectedOption === quiz.back.solucionCorrecta) {
      setScore(score + 1);
    }
    setShowAnswer(true);
  };

  const handleNext = () => {
    setShowAnswer(false);
    setSelectedOption(null);
    if (currentQuiz < quizzes.length - 1) {
      setCurrentQuiz(currentQuiz + 1);
    } else {
      alert(`¡Terminaste! Tu puntaje final es: ${score}/${quizzes.length}`);
    }
  };

  const quiz = quizzes[currentQuiz];

  return (
    <div className="quiz-container">
      <h2>{quiz.front.problema}</h2>
      <p>{quiz.front.escenario}</p>
      <ul>
        {quiz.back.opciones.map((opcion, index) => (
          <li key={index}>
            <label>
              <input
                type="radio"
                name="option"
                value={index}
                checked={selectedOption === index}
                onChange={() => setSelectedOption(index)}
                disabled={showAnswer}
              />
              {opcion}
            </label>
          </li>
        ))}
      </ul>
      {!showAnswer && (
        <button
          onClick={handleSubmit}
          disabled={selectedOption === null}
        >
          Confirmar
        </button>
      )}
      {showAnswer && (
        <div>
          <p>
            {selectedOption === quiz.back.solucionCorrecta
              ? "¡Correcto!"
              : "Incorrecto."}
          </p>
          <p>{quiz.back.casoEjemplo}</p>
          <button onClick={handleNext}>Siguiente</button>
        </div>
      )}
      <p>Puntaje actual: {score}</p>
    </div>
  );
};

export default QuizComponent;