import { useNavigate } from "react-router-dom";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import "jspdf-autotable";

const ComparativeTable = () => {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate("/CapitanPresencial/Final");
  };

  const headers = [
    "Equipo 1", "Equipo 2", "Equipo 3", "Equipo 4", "Equipo 5",
    "Equipo 6", "Equipo 7", "Equipo 8", "Equipo 9"
  ];

  const rows = [
    "Puntos Ganados",
    "5 Fuerzas Porter (% variación)",
    "Digital Drivers Amit&Zott (% variación)",
    "Madurez Digital (% variación)",
    "ROI",
    "Market Share",
    "Ticket Medio Compra",
    "Cross Selling",
    "Conversion Rate",
    "Revenue Growth",
    "Ecosystem Strategy"
  ];

  const data = {
    "Equipo 1": [90, 5.3, 7.1, 4.8, 12.5, 30, 45, 22, 10, 8.5, 9.2]
  };

  const handleDownloadExcel = () => {
    const worksheet = XLSX.utils.aoa_to_sheet([
      ["", ...headers], 
      ...rows.map((row, index) => [
        row,
        data["Equipo 1"][index] || "",
        ...new Array(headers.length - 1).fill("")
      ])
    ]);

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Comparativa Equipos");
    const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
    const excelBlob = new Blob([excelBuffer], { type: "application/octet-stream" });
    saveAs(excelBlob, "Comparativa_Equipos.xlsx");
  };

  const handleDownloadPDF = () => {
    const doc = new jsPDF();
    doc.text("Comparativa de Equipos", 14, 15);
    
    const tableData = rows.map((row, index) => [
      row,
      data["Equipo 1"][index] || "",
      ...new Array(headers.length - 1).fill("")
    ]);

    doc.autoTable({
      head: [["", ...headers]],
      body: tableData,
      startY: 20
    });

    doc.save("Comparativa_Equipos.pdf");
  };

  return (
    <div style={{ padding: "20px" }}>
      <table
        style={{
          width: "100%",
          borderCollapse: "collapse",
          textAlign: "left",
          border: "2px solid navy"
        }}
      >
        <thead>
          <tr style={{ backgroundColor: "navy", color: "white" }}>
            <th style={{ padding: "10px", border: "1px solid white" }}> </th>
            {headers.map((header, index) => (
              <th key={index} style={{ padding: "10px", border: "1px solid white" }}>
                {header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {rows.map((row, rowIndex) => (
            <tr key={rowIndex} style={{ backgroundColor: rowIndex % 2 === 0 ? "#E0F7FA" : "white" }}>
              <td style={{ padding: "10px", border: "1px solid navy", fontWeight: "bold" }}>{row}</td>
              {headers.map((_, colIndex) => (
                <td key={colIndex} style={{ padding: "10px", border: "1px solid navy" }}>
                  {colIndex === 0 ? data["Equipo 1"][rowIndex] || "" : ""}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      <div style={{ marginTop: "20px", display: "flex", gap: "10px" }}>
        <button
          onClick={handleGoBack}
          style={{
            padding: "10px 20px",
            backgroundColor: "green",
            color: "white",
            border: "none",
            cursor: "pointer"
          }}
        >
          ← Regresar
        </button>
        <button
          onClick={handleDownloadExcel}
          style={{
            padding: "10px 20px",
            backgroundColor: "blue",
            color: "white",
            border: "none",
            cursor: "pointer"
          }}
        >
          📥 Descargar Excel
        </button>
        <button
          onClick={handleDownloadPDF}
          style={{
            padding: "10px 20px",
            backgroundColor: "red",
            color: "white",
            border: "none",
            cursor: "pointer"
          }}
        >
          📥 Descargar PDF
        </button>
      </div>
    </div>
  );
};

export default ComparativeTable;
