import { useState, useEffect } from 'react';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useNavigate } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';

import carta1 from './cartas/1.png';
import carta2 from './cartas/2.png';
import carta3 from './cartas/3.png';
import carta4 from './cartas/4.png';
import carta5 from './cartas/5.png';
import carta6 from './cartas/6.png';
import carta7 from './cartas/7.png';
import carta8 from './cartas/8.png';
import carta9 from './cartas/9.png';
import carta10 from './cartas/10.png';
import carta11 from './cartas/11.png';
import carta12 from './cartas/12.png';
import carta13 from './cartas/13.png';
import carta14 from './cartas/14.png';
import carta15 from './cartas/15.png';
import carta16 from './cartas/16.png';
import carta17 from './cartas/17.png';

// internos
import puntosHelpers from "../../variables/puntos.js"
import tokenHelpers from "../../variables/tokens.js"
import equipoHelpers from "../../variables/equipos.js"
import cartasHelpers from "../../variables/cartas.js";
import jugadasHelpers from "../../variables/jugadas.js"
import sprintHelpers from "../../variables/sprints.js"


const decodeToken = tokenHelpers.decodeToken;
const equiposEnPartida = equipoHelpers.equiposEnPartida;
const fetchData = puntosHelpers.fetchData;
const fetchModifier = puntosHelpers.fetchModifier;
const numeroSprintsEnPartida = sprintHelpers.numeroSprintsEnPartida;
const cartasEnMazo = cartasHelpers.cartasEnMazo;
const cartaPorNumero = cartasHelpers.cartaPorNumero;
const cartaPorID = cartasHelpers.cartaPorID;
const variasCartasPorID = cartasHelpers.variasCartasPorID;
const listaMazos = cartasHelpers.listaMazos;
const verJugadasEquipo = jugadasHelpers.verJugadasEquipo;

const phases = ['Fase 1', 'Fase 2', 'Fase 3'];

const categories = [
  {
    name: 'Personas',
    cards: [
      { id: 1, name: 'Dueño del producto', cost: 15000, value: 80, background: carta1 },
      { id: 2, name: 'Experto en la materia', cost: 12000, value: 70, background: carta2 },
      { id: 3, name: 'Analista de negocios', cost: 13000, value: 75, background: carta3 },
      { id: 4, name: 'Ingeniero en software', cost: 10000, value: 60, background: carta4 },
      { id: 5, name: 'Ingeniero en datos', cost: 10000, value: 60, background: carta5 },
      { id: 6, name: 'Científico de datos', cost: 11000, value: 65, background: carta6 },
      { id: 7, name: 'Ingeniero en ML', cost: 10000, value: 70, background: carta7 },
      { id: 8, name: 'Gestor de Ecosistema', cost: 10000, value: 70, background: carta8 },
      { id: 9, name: 'Experto Financiero', cost: 10000, value: 70, background: carta9 },
      //  { id: 10, name: 'Maestro Scrum', cost: 50000, value: 50, background: carta10 },
      //  { id: 11, name: 'Coach Ágil', cost: 50000, value: 50, background: carta11 },
      //  { id: 12, name: 'Experto en Marketing', cost: 100000, value: 60, background: exampleImage },
      //  { id: 13, name: 'Experto experiencia de cliente', cost: 80000, value: 60, background: exampleImage },
      //  { id: 14, name: 'Gerente de proyectos TI', cost: 100000, value: 70, background: exampleImage },
      //  { id: 15, name: 'Especialista en seguridad informática', cost: 100000, value: 70, background: exampleImage },
      //  { id: 16, name: 'Director de tecnología (CTO)', cost: 100000, value: 75, background: exampleImage },
      //  { id: 17, name: 'Project manager officer (PMO)', cost: 90000, value: 65, background: exampleImage },
    ],
    multipliers: [3, 2, 1],
  },
  {
    name: 'Tecnología',
    cards: [
      { id: 18, name: 'Data-based solutions', cost: 15000, value: 60, background: carta10 },
      { id: 19, name: 'On-Demand Services', cost: 15000, value: 60, background: carta11 },
      { id: 20, name: 'People network base solutions', cost: 15000, value: 60, background: carta12 },
      { id: 21, name: 'Integrated Solutions', cost: 15000, value: 60, background: carta13 },
      { id: 22, name: 'Smart Solutions', cost: 15000, value: 60, background: carta14 },
      //  { id: 23, name: 'Accelerate decision', cost: 15000, value: 60, background: exampleImage },
      //  { id: 24, name: 'Digital Customer Relationship', cost: 15000, value: 60, background: exampleImage },
      //  { id: 25, name: 'People network base', cost: 15000, value: 60, background: exampleImage },
      //  { id: 26, name: 'Business Network', cost: 15000, value: 60, background: exampleImage },
      //  { id: 27, name: 'Door opening Devices', cost: 15000, value: 60, background: exampleImage },
      //  { id: 28, name: 'Customized Channels', cost: 15000, value: 60, background: exampleImage },
      //  { id: 29, name: 'Ubiquitous Access', cost: 15000, value: 60, background: exampleImage },
      //  { id: 30, name: 'Community Channels', cost: 15000, value: 60, background: exampleImage },
      //  { id: 31, name: 'Platforms Channels', cost: 15000, value: 60, background: exampleImage },
      //  { id: 32, name: 'Smart Thing Channels', cost: 15000, value: 60, background: exampleImage },
      //  { id: 33, name: 'Micro segments', cost: 15000, value: 60, background: exampleImage },
      //  { id: 34, name: 'Global Reach', cost: 15000, value: 60, background: exampleImage },
      //  { id: 35, name: 'Digital Consumers', cost: 15000, value: 60, background: exampleImage },
      //  { id: 36, name: 'Global reach by Business network', cost: 15000, value: 60, background: exampleImage },
      //  { id: 37, name: 'Extended reach', cost: 15000, value: 60, background: exampleImage },
      //  { id: 38, name: 'People network', cost: 15000, value: 60, background: exampleImage },
      //  { id: 39, name: 'Business network Customer Side', cost: 15000, value: 60, background: exampleImage },
      //  { id: 40, name: 'Data Monetization', cost: 15000, value: 60, background: exampleImage },
      //  { id: 41, name: 'Digital Service Monetization', cost: 15000, value: 60, background: exampleImage },
      //  { id: 42, name: 'Cloud Infra Monetization', cost: 15000, value: 60, background: exampleImage },
      // { id: 43, name: 'Community Access', cost: 15000, value: 60, background: exampleImage },
      // { id: 44, name: 'Business Network Access', cost: 15000, value: 60, background: exampleImage },
      //  { id: 45, name: 'Smart Things', cost: 15000, value: 60, background: exampleImage },
      //  { id: 46, name: 'Adaptive Pricing', cost: 15000, value: 60, background: exampleImage },
      // { id: 47, name: 'Pay per use', cost: 15000, value: 60, background: exampleImage },
      //  { id: 48, name: 'Group based pricing', cost: 15000, value: 60, background: exampleImage },
      //  { id: 49, name: 'Business to Business', cost: 15000, value: 60, background: exampleImage },
      //  { id: 50, name: 'Data Content', cost: 15000, value: 60, background: exampleImage },
      //  { id: 51, name: 'Cloud Provider', cost: 15000, value: 60, background: exampleImage },
      //  { id: 52, name: 'Community Providers', cost: 15000, value: 60, background: exampleImage },
      // { id: 53, name: 'Business Platform Providers', cost: 15000, value: 60, background: exampleImage },
      //  { id: 54, name: 'Smart Things Partners', cost: 15000, value: 60, background: exampleImage },
      //  { id: 55, name: 'People Networks', cost: 15000, value: 60, background: exampleImage },
      //  { id: 56, name: 'Business Networks', cost: 15000, value: 60, background: exampleImage },
      //  { id: 57, name: 'Data as a Source', cost: 15000, value: 60, background: exampleImage },
    ],
    multipliers: [3, 2, 1],
  },
  {
    name: 'Partners',
    cards: [
      { id: 58, cost: 60000, value: 60, background: carta15 },
      { id: 59, cost: 60000, value: 60, background: carta16 },
      { id: 60, cost: 60000, value: 60, background: carta17 },
    ],
    multipliers: [3, 2, 1],
  },
];

const Card = ({ id, background }) => {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: 'CARD',
    item: { id },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  }), [id]);

  return (
    <div
      ref={drag}
      style={{
        width: '100px',
        height: '150px',
        backgroundImage: `url(${background})`,
        backgroundSize: 'cover',
        margin: '5px',
        border: '1px solid #000',
        cursor: 'grab',
        opacity: isDragging ? 0.5 : 1,
      }}
    />
  );
};

const DropZone = ({ phase, category, onDrop, children, onRemove, isLocked }) => {
  const [{ isOver }, drop] = useDrop(() => ({
    accept: 'CARD',
    drop: (item) => {
      if (!children && !isLocked) {
        onDrop(item, phase, category);
      }
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
  }), [phase, category, children, isLocked]);

  return (
    <div
      ref={drop}
      style={{
        width: '100px',
        height: '150px',
        margin: '5px',
        backgroundColor: isOver ? '#e0e0e0' : '#f9f9f9',
        border: '1px dashed #000',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
      onClick={() => !isLocked && children && onRemove(phase, category)}
    >
      {children && (
        <div
          style={{
            width: '100%',
            height: '100%',
            backgroundImage: `url(${children.background})`,
            backgroundSize: 'cover',
          }}
        />
      )}
    </div>
  );
};

const RoadmapSprint = ({ rtdoNeto }) => {
  const [cards, setCards] = useState(categories.flatMap((c) => c.cards));
  const [droppedCards, setDroppedCards] = useState({});
  const [financials, setFinancials] = useState(0);
  const [isLocked, setIsLocked] = useState(false);
  const navigate = useNavigate();



  //////////////
  /*  CONFIG  */
  //////////////

  // conf
  const { t, i18n } = useTranslation();
  const [idioma, setIdioma] = useState("es")
  const [path, setPath] = useState("../assets/es/cartas/")
  const [loading, setLoading] = useState(true) // hacer visible los elementos al empezar

  // datos usuario
  const [nombre, setNombre] = useState("");
  const [institucion, setInstitucion] = useState("");
  const [fecha, setFecha] = useState("")

  // general
  const [selectedPartida, setSelectedPartida] = useState(null);
  const [partidaVirtual, setPartidaVirtual] = useState(false);
  const [selectedEquipo, setSelectedEquipo] = useState(null);
  const [sprintsJugados, setSprintsJugados] = useState([])
  const [selectedCanvas, setSelectedCanvas] = useState("canvasPostit")
  const arranqueListo = false

  // cucardones
  const [topTres, setTopTres] = useState(null)
  const [topTresMejora, setTopTresMejora] = useState(null)
  const [topTresPorter, setTopTresPorter] = useState(null)
  const [topTresAmit, setTopTresAmit] = useState(null)
  const [puntosGanados, setPuntosGanados] = useState(null)
  const [mejoraGanada, setMejoraGanada] = useState(null)
  const [cucardonesPuntos, setCucardonesPuntos] = useState(null)
  const [cucardonesQuizzes, setCucardonesQuizzes] = useState(null)
  const [cucardonesMejora, setCucardonesMejora] = useState(null)

  // sprint 0
  const [sliderValues, setSliderValues] = useState([5, 5, 5, 5]); // AMIT
  const [sliderValues2, setSliderValues2] = useState([5, 5, 5, 5, 5]); // PORTER
  const [sliderValuesData, setsliderValuesData] = useState([5, 5, 5, 5, 5, 5, 5, 5, 5]); // DATA MATURITY
  const [valWheel, setValWheel] = useState([5, 5, 5, 5]); // valwheel

  // metricas
  const [TablaMetricas, setTablaMetricas] = useState([]);
  const [datosAñoActual, setdatosAñoActual] = useState([]);
  const [datosAñoActualE, setdatosAñoActualE] = useState([]);
  const [tableData, settableData] = useState([]);

  // cartas y jugadas
  const [jugadas, setJugadas] = useState(null);
  const [cartas, setCartas] = useState([]);
  const [images, setImages] = useState([]);
  const [dimData, setDimData] = useState([]); // para chart digital innovation measurement
  const [ecosystem_val, setEcoSystemVal] = useState({}) // ECOSYSTEM
  const [sustainData, setSustainData] = useState({}) // SUSTAINABILITY

  // canvas
  const correctorGlobal = 95 // modificar aca si las banderas se salen de los recuadros
  const [tableroWidth, setTableroWidth] = useState(0);
  const [tableroHeight, setTableroHeight] = useState(0);
  const [tableroWidthZero, setTableroWidthZero] = useState(0);
  const [tableroHeightZero, setTableroHeightZero] = useState(0);
  const [droppedCardss, setDroppedCardss] = useState({
    1: [],
    2: [],
    3: [],
    4: [],
    5: [],
    6: [],
    7: [],
    8: [],
    9: []
  });

  // devuelve true si se jugo el sprint y falso si no
  function jugamosEsteSprint(keyword, data) {
    return data.some(item => item.nombre === keyword);
  }

  async function setearConfDesdeToken() {
    // recibir token de local storage
    const token = localStorage.getItem('token')
    console.log(`jugadas arranque setearConf ${JSON.stringify(jugadas)} tipo ${typeof jugadas}`)

    if (!token) { console.error(`no hay token!`); return null }
    console.log('Token found:', token);
    const claims = decodeToken(token);
    console.log('Token claims:', claims);

    // armar fecha
    const date = new Date();
    var now = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
    setFecha(now);
    console.log(`fecha es ${now}`);

    // armar idioma
    if (claims.idioma) {
      setIdioma(claims.idioma)
      console.log(`seteando path a ../assets/${claims.idioma}/cartas/...`)
      setPath(`../assets/${claims.idioma}/cartas/`)
      i18n.changeLanguage(claims.idioma)

      // setear fecha como string i18n
      now = date.toLocaleDateString(claims.idioma, {
        day: '2-digit',
        month: 'long',
        year: 'numeric',
      });
    }

    // setear datos usuario
    if (claims.nombre && claims.institucion) {
      setNombre(claims.nombre)
      setInstitucion(claims.institucion)
      console.log(`Generando final para ${claims.nombre}, ${claims.institucion}, el dia ${fecha}`)
    }

    // configuracion de juego
    if (claims.role === "Player" || claims.role === "Captain") {

      // setear partida y equipo
      setSelectedPartida(claims.partida)
      setSelectedEquipo(claims.equipo)
      console.log(`Seteando pagina final para partida ${claims.partida}, equipo ${claims.equipo}...`)

      // datos asociados al equipo
      var datosEquipos, datosEquipo, datosSprints, datosJugadas, datosMejora
      try {
        datosEquipos = await equiposEnPartida(claims.partida)
        datosEquipo = await fetchData(claims.partida, claims.equipo)
        datosSprints = await numeroSprintsEnPartida(claims.partida)
        datosJugadas = await verJugadasEquipo(claims.partida, claims.equipo)
        datosMejora = await getMejora(datosEquipo[0])

        const mejora = {
          total: Math.round((datosMejora.mejora_total - 1) * 1000, 0),
          porter: Math.round((datosMejora.mejora_porter) * 1000, 0), // agregamos un 0 hasta determinar pq es distinto del grafico
          amit: Math.round((datosMejora.mejora_amit) * 1000, 0)
        }
        setMejoraGanada(mejora)

        //console.log(`datos equipo son ${JSON.stringify(datosEquipo)}`)
        console.log(`Equipo esta en sprint id ${datosEquipo[0]["sprint_id"]}`)
        console.log(`datos de todos los sprints en p son ${JSON.stringify(datosSprints)}`)
        console.log(`datos de todos las jugadas son ${JSON.stringify(datosJugadas)}`)
        console.log(`datos mejora son ${JSON.stringify(mejora)}`)

        // armar un array de nombres de sprints jugados
        const datosSprintsJugados = [...new Set(datosSprints.map(item => item.nombre))];
        setSprintsJugados(datosSprintsJugados);

        // ir alimentando a cada seccion
        console.log(`enviando a top3: ${JSON.stringify(datosEquipos)}`)
        // metricas se arma solo
        // blue ocean? lo encuentra

        // ecosystem
        var ecosystem
        const ecosystemResp = 1
        if (!ecosystemResp || !ecosystemResp.success) { console.error(`no hubo respuesta de ecosystemResp`) }
        else { ecosystem = ecosystemResp.data }
        setEcoSystemVal(ecosystem)

        // empezar a armar jugadas, DIM chart, Sustainability
        setJugadas(datosJugadas) // traer cartas jugadas
        console.log(`jugadas seteado ${JSON.stringify(jugadas)} tipo ${typeof jugadas}`)

        if (claims.presencial === 1) {
          // JUEGO PRESENCIAL


        } else {
          // JUEGO VIRTUAL
          setPartidaVirtual(true)

          // traer el framework basado en partida



          // setear img framework
          var selecciondeCanvas

          console.log(`canvas seleccionado es ${selecciondeCanvas}`)
          setSelectedCanvas(selecciondeCanvas)

          // traer recuadros canvas del framework



          // convierte a los recuadros en un array de IDs
          var recuadrosViejosArr = 1
          console.log(`recuadrosViejosArr es ${JSON.stringify(recuadrosViejosArr)}`)
          var recuadrosViejos = {}

          // si se jugo data driven virt, agregar recuadros de ese
          if (datosSprintsJugados.includes("DataDriven")) {
            // traer recuadros DD


            // 
            var recuadrosViejosArrDD = 1
            console.log(`recuadrosViejosArr data driven es ${JSON.stringify(recuadrosViejosArrDD)}`)

            // mergearlo al array
            recuadrosViejosArr = recuadrosViejosArr.concat(recuadrosViejosArr, recuadrosViejosArrDD)
            console.log(`recuadrosViejosArr con data driven es ${JSON.stringify(recuadrosViejosArrDD)}`)
          }

          // filtrar jugadas para los que tengan recuadro
          if (datosJugadas) {
            datosJugadas.filter(x => x.recuadro)
            console.log(`jugadas anteriores con recuadro son ${JSON.stringify(datosJugadas)}`)

            // traer todas las imagenes para las cartas jugadas previamente
            const arrayCartasViejas = datosJugadas.map(x => x.carta_id)
            console.log(`arrayCartasViejas es ${arrayCartasViejas}`)
            const cartasViejasData = await variasCartasPorID(arrayCartasViejas)
            console.log(`cartasViejasData es ${JSON.stringify(cartasViejasData)}`)



            // armar las imagenes de los sprints anteriores y ponerlos en el tablero
            // mapear los recuadros, sin que se puedan deseleccionar
            try {
              for (let i = 0; i < recuadrosViejosArr.length; i++) {
                var keyD = recuadrosViejosArr[i];
                var jugD = datosJugadas.filter(x => x.recuadrocanvas_id === keyD);

                // Convertir jugadas a imágenes
                console.log(`jugD viejo es ${JSON.stringify(jugD)}`);
                console.log(`cartasviejasdata es array?`, Array.isArray(cartasViejasData)); // Check if it logs true


                for (let i = 0; i < jugD.length; i++) {
                  var carta = cartasViejasData.find(x => x && x.id === jugD[i].carta_id);

                  if (carta) {
                    var svgpath = carta.svg_front;
                    var imgInfo = 1

                    if (imgInfo) {
                      var name = imgInfo.name;
                      var src = imgInfo.src;
                      jugD[i] = { name: name, src: src };
                    }
                  }
                }

                console.log(`jugD nuevo es ${JSON.stringify(jugD)}`);
                recuadrosViejos[keyD] = jugD;
              }

              console.log(`recuadrosViejos son ${JSON.stringify(recuadrosViejos)}`);
              setDroppedCards(recuadrosViejos);
            } catch (error) {
              console.error(error);
            }

          } else {
            for (let i = 0; i < recuadrosViejosArr.length; i++) {
              var keyD = recuadrosViejosArr[i]
              recuadrosViejos[keyD] = []
            }
            setDroppedCards(recuadrosViejos)
          }

        }

      } catch (error) {
        console.error(`No se pudo traer datos en conf ini:`, error)
      }

    } else if (claims.role === "Admin") {
      //setUserType("Admin")
    } else {
      console.error("y vos quien sos ???")
    }

  }



  async function getMejora(e) {
    var m = e
    const resp = await fetchModifier(m.partida_id, m.id)
    if (!resp || !resp.success) {
      console.error(`getMejora: error trayendo equipo ${m.id}`)
    } else {
      m.mejora_total = resp.data.total;
      m.mejora_porter = resp.data.porter;
      m.mejora_amit = resp.data.amit;

      console.log(`top3mejora: equipo ${m.id} tiene mejora total ${JSON.stringify(m.mejora_total)}`)
    }
    return m
  }

  useEffect(() => {
    const savedState = localStorage.getItem('gameState');
    if (savedState) {
      const { droppedCards: savedDroppedCards, financials: savedFinancials } = JSON.parse(savedState);
      setDroppedCards(savedDroppedCards);
      setFinancials(savedFinancials);
      setIsLocked(true); // Bloquear el juego si ya hay un estado guardado
    }
  }, []);

  const handleDrop = (item, phase, category) => {
    if (isLocked) return;

    const card = cards.find((c) => c.id === item.id);
    if (!card || droppedCards[`${category.name}-${phase}`]) return;

    setDroppedCards((prev) => ({
      ...prev,
      [`${category.name}-${phase}`]: card,
    }));

    setCards((prev) => prev.filter((c) => c.id !== card.id));

    const phaseIndex = phases.indexOf(phase);
    const multiplier = category.multipliers[phaseIndex];
    setFinancials((prev) => prev + card.cost * multiplier);
  };

  const handleRemove = (phase, category) => {
    if (isLocked) return;

    const key = `${category.name}-${phase}`;
    const card = droppedCards[key];
    if (!card) return;

    setDroppedCards((prev) => {
      const updated = { ...prev };
      delete updated[key];
      return updated;
    });

    setCards((prev) => [...prev, card]);

    const phaseIndex = phases.indexOf(phase);
    const multiplier = category.multipliers[phaseIndex];
    setFinancials((prev) => prev - card.cost * multiplier);
  };

  const handleSendPlay = () => {
    setIsLocked(true);
    const gameState = { droppedCards, financials };
    localStorage.setItem('gameState', JSON.stringify(gameState));
  };

  const handleGoBack = () => {
    navigate('/CapitanPresencial/Final');
  };

  useEffect(() => {
    setearConfDesdeToken()
  }, [])

  return (
    <DndProvider backend={HTML5Backend}>
      <div style={{ padding: '20px' }}>
        <h1>Roadmap Sprint</h1>

        <div style={{ display: 'flex', marginBottom: '10px', color: 'white' }}>
          <div style={{ width: '100px', textAlign: 'center' }}></div>
          {phases.map((phase) => (
            <div key={phase} style={{ width: '100px', textAlign: 'center' }}>{phase}</div>
          ))}
        </div>

        {categories.map((category) => (
          <div key={category.name} style={{ display: 'flex', alignItems: 'center', marginBottom: '10px', color: 'white' }}>
            <div style={{ width: '100px', textAlign: 'center', fontWeight: 'bold' }}>{category.name}</div>
            {phases.map((phase) => (
              <DropZone
                key={`${category.name}-${phase}`}
                phase={phase}
                category={category}
                onDrop={handleDrop}
                onRemove={handleRemove}
                isLocked={isLocked}
              >
                {droppedCards[`${category.name}-${phase}`]}
              </DropZone>
            ))}
          </div>
        ))}

        <div style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap', marginTop: '20px' }}>
          {!isLocked &&
            cards.map((card) => (
              <Card key={card.id} {...card} />
            ))}
        </div>

        <div style={{ marginTop: '20px' }}>
          <h2>Projected budget</h2>
          <p>Total: ${financials.toLocaleString()} VS TotalBudget: {rtdoNeto.toLocaleString()}</p>
        </div>

        <div style={{ marginTop: '20px', display: 'flex', justifyContent: 'space-between' }}>
          {!isLocked && (
            <button
              onClick={handleSendPlay}
              style={{
                padding: '10px 20px',
                backgroundColor: 'blue',
                color: 'white',
                border: 'none',
                cursor: 'pointer',
              }}
            >
              Enviar Jugada
            </button>
          )}
          <button
            onClick={handleGoBack}
            style={{
              padding: '10px 20px',
              backgroundColor: 'green',
              color: 'white',
              border: 'none',
              cursor: 'pointer',
            }}
          >
            ← Regresar
          </button>
        </div>
      </div>
    </DndProvider>
  );
};

export default RoadmapSprint;
