// BarraSprint
// Crea el navbar arriba de los sprints y quizzes
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import tokenHelpers from "../../variables/tokens.js";
import puntosHelpers from "../../variables/puntos.js";
// i18n
import { Trans } from 'react-i18next'
// assets
import { Row, Col, Card, CardBody, Button } from 'reactstrap';
import imgOk from "assets/img/send_ok.png"
import img2 from "assets/img/corriendo.gif";

// barra de progreso
import SprintTimeLine from 'components/Sprints/SprintTimeLine';
import QuizzPrize from "components/Sprints/QuizzPrize.jsx";

function secondsToMinutesAndSeconds(seconds) {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;

  const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
  const formattedSeconds = remainingSeconds < 10 ? `0${remainingSeconds}` : remainingSeconds;

  return `${formattedMinutes}:${formattedSeconds}`;
}

function BarraNombreSprint(nombreDelSprint) {
  console.log(`BarraNombreSprint: ${nombreDelSprint}`)

  const visible = 1
  //const [visible, setVisible] = useState(1);

  const mensajeSprint = `Sprint ${nombreDelSprint}`;

  const colorMapping = {
    Trends: '#92C84A',
    Sustainability: '#4a7420',
    Patterns: '#FFC82A',
    BlueOcean: '#00BBF1',
    DigitalDrivers: '#00506A',
    Platforms: '#51A8B1',
    DataDriven: '#ED2F59',
    Test: '#DA5A81',
    Metrics: '#F58634',
    CustomerJourney: '#00BBF1',
    Ecosystem: '#96031d',
    Roadmap: '#e49d13',
  };

  const colorFondoBarra = colorMapping[nombreDelSprint] || 'blue';

  // timer
  //useEffect(() => {
  //	const timerBarraNombre = setTimeout(() => {
  //		setVisible(false);
  //	}, 3000);
  //	return () => clearTimeout(timerBarraNombre);
  //}, []);

  const barraEstilo = {
    backgroundColor: colorFondoBarra, // Usar la variable aquí
    color: 'white',
    padding: '10px',
    marginTop: '80px',
    position: 'absolute',
    top: '50%',
    left: '0',
    transform: 'translateY(-50%)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    opacity: visible ? 1 : 0,
    transition: 'opacity 1s ease',
    zIndex: 1000
  };

  const estiloTexto = {
    fontSize: '50px',
    marginRight: '20px'
  };

  const imagenGifEstilo = {
    width: '100px',
    height: '100px'
  };

  return (
    <div style={barraEstilo}>
      <span style={estiloTexto}>{mensajeSprint}</span>
      <img src={img2} alt="GIF" style={imagenGifEstilo} />
    </div>
  )

}
const decodeToken = tokenHelpers.decodeToken;
const fetchData = puntosHelpers.fetchData;

const BarraManito = (botonArrancar, botonGuardar, tiempoRestante, arranqueListo, yaSeGuardo, movimientosDisponibles, nombreSprint, idioma, cartasLoaded, todosSprints) => {
  const [quizzesJugados, setQuizzesJugados] = useState(0);
  const [quizzesGanados, setQuizzesGanados] = useState(0);

  useEffect(() => {
    async function obtenerDatosEquipo() {
      const token = localStorage.getItem('token');
      if (!token) return console.error('No se encontró token');

      const claims = decodeToken(token);
      if (!claims?.partida || !claims?.equipo) return console.error('Datos de partida o equipo no válidos');

      try {
        const datosEquipo = await fetchData(claims.partida, claims.equipo);
        const equipoData = Object.values(datosEquipo)[0];
        console.log(equipoData);
        if (equipoData) {
          setQuizzesJugados(equipoData.quizzesJugados || 0);
          setQuizzesGanados(equipoData.quizzesGanados || 0);
        }
      } catch (error) {
        console.error('Error al obtener datos del equipo:', error);
      }
    }

    obtenerDatosEquipo();
  }, []);


  //const { t, i18n } = useTranslation(); 
  //i18n.changeLanguage(idioma)

  var arrSprints
  if (!todosSprints) { arrSprints = [] }
  else { arrSprints = todosSprints.map(s => s.nombre) }

  return (
    <>
      <Row>
        <Col xs="12">
          <Card>
            <CardBody style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              {/* Botón "Ya terminé, ir al quiz" */}
              {yaSeGuardo ? (null) : (
                <form onSubmit={botonGuardar} style={{ marginRight: '30px' }}>
                  <Button style={{ backgroundColor: '#007BFF', borderColor: '#004080', borderWidth: '2px', display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '10px' }}>
                    <img src={imgOk} alt="Ir al quiz" style={{ width: '100px', height: '50px' }} />
                  </Button>
                </form>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row >
    </>
  );
};

export default { BarraManito };
