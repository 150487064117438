import { useState, useEffect } from "react";
import { useTranslation, Trans } from 'react-i18next';
import img from "../assets/img/fondo3.png";
import {
  Card,
  CardBody,
  Row,
  Col,
} from "reactstrap";
import { host } from "../variables/connection.js";
import resultadosHelper from "../variables/resultados.js";
import tokensHelpers from "../variables/tokens.js";
import img2 from "assets/img/corriendo.gif";
import Comparativa from "../components/Comparativa/Comparativa.js";

// internos
import puntosHelpers from "../variables/puntos.js"
import tokenHelpers from "../variables/tokens.js"
import equipoHelpers from "../variables/equipos.js"
import cartasHelpers from "../variables/cartas.js";
import jugadasHelpers from "../variables/jugadas.js"
import sprintHelpers from "../variables/sprints.js"
import canvasHelpers from "../variables/canvas.js"



const equiposEnPartida = equipoHelpers.equiposEnPartida;
const fetchData = puntosHelpers.fetchData;
const fetchModifier = puntosHelpers.fetchModifier;
const numeroSprintsEnPartida = sprintHelpers.numeroSprintsEnPartida;
const cartasEnMazo = cartasHelpers.cartasEnMazo;
const cartaPorNumero = cartasHelpers.cartaPorNumero;
const cartaPorID = cartasHelpers.cartaPorID;
const variasCartasPorID = cartasHelpers.variasCartasPorID;
const listaMazos = cartasHelpers.listaMazos;
const verJugadasEquipo = jugadasHelpers.verJugadasEquipo;



const resultados = resultadosHelper.tablaResultados;
const decodeToken = tokensHelpers.decodeToken;




const tableContainerStyle = {
  display: "flex",
  justifyContent: "space-between",
  width: "100%",
};

const tableContainerStyle2 = {
  display: "flex",
  justifyContent: "center",
  width: "100%",
};

const tableStyle = {
  width: "30%", // Ancho ajustable
  borderCollapse: "collapse",
  margin: "20px 0",
};

const thStyle = {
  backgroundColor: "#f2f2f2",
  border: "1px solid #ddd",
  padding: "8px",
  textAlign: "center",
  color: "black",
};

const tdStyle = {
  border: "1px solid #ddd",
  padding: "8px",
  textAlign: "left",
  color: "white",
};

function BarraNombreSprint() {
  const visible = 1;

  const mensajeSprint = "Sprint Roadmap";

  const colorMapping = {
    Roadmap: '#e49d13',
  };

  const colorFondoBarra = colorMapping[mensajeSprint] || 'blue';

  // timer
  //useEffect(() => {
  //	const timerBarraNombre = setTimeout(() => {
  //		setVisible(false);
  //	}, 3000);
  //	return () => clearTimeout(timerBarraNombre);
  //}, []);

  const barraEstilo = {
    backgroundColor: colorFondoBarra,
    color: 'white',
    padding: '10px',
    marginTop: '80px',
    position: 'absolute',
    top: '50%',
    left: '0',
    transform: 'translateY(-50%)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    opacity: visible ? 1 : 0,
    transition: 'opacity 1s ease',
    zIndex: 1000,
    marginBottom: '50px', // Agregar margen inferior
  };

  const estiloTexto = {
    fontSize: '50px',
    marginRight: '20px'
  };

  const imagenGifEstilo = {
    width: '100px',
    height: '100px'
  };

  return (
    <div style={barraEstilo}>
      <span style={estiloTexto}>{mensajeSprint}</span>
      <img src={img2} alt="GIF" style={imagenGifEstilo} />
    </div>
  )

}

function ComparativaPage(props) {
  const { t, i18n } = useTranslation();

  const [loading, setLoading] = useState(true);

  const [userType, setUserType] = useState([]);
  const [partidas, setPartidas] = useState([]);
  const [selectedPartida, setSelectedPartida] = useState();
  const [nombreEquipo, setNombreEquipo] = useState("");
  const [equipos, setEquipos] = useState([]);
  const [selectedEquipo, setSelectedEquipo] = useState();

  const [datosAñoActual, setdatosAñoActual] = useState([]);
  const [datosAñoActualE, setdatosAñoActualE] = useState([]);
  const [TablaMetricas, setTablaMetricas] = useState([]);
  const [tableData, settableData] = useState([]);
  const [rtdoNeto, setRtdoNeto] = useState(null); // Variable para guardar el RTDO NETO
  const [nombreSprint, setNombreSprint] = useState("Roadmap");

  const [quizzesJugados, setQuizzesJugados] = useState(0);
  const [quizzesGanados, setQuizzesGanados] = useState(0);

  useEffect(() => {
    async function obtenerDatosEquipo() {
      const token = localStorage.getItem('token');
      if (!token) return console.error('No se encontró token');

      const claims = decodeToken(token);
      if (!claims?.partida || !claims?.equipo) return console.error('Datos de partida o equipo no válidos');

      try {
        const datosEquipo = await fetchData(claims.partida, claims.equipo);
        const equipoData = Object.values(datosEquipo)[0];
        console.log(equipoData);
        if (equipoData) {
          setQuizzesJugados(equipoData.quizzesJugados || 0);
          setQuizzesGanados(equipoData.quizzesGanados || 0);
        }
      } catch (error) {
        console.error('Error al obtener datos del equipo:', error);
      }
    }

    obtenerDatosEquipo();
  }, []);


  //////////////
  /*  CONFIG  */
  //////////////

  // conf
  const [idioma, setIdioma] = useState("es")
  const [path, setPath] = useState("../assets/es/cartas/")

  // datos usuario
  const [nombre, setNombre] = useState("");
  const [institucion, setInstitucion] = useState("");
  const [fecha, setFecha] = useState("")

  // general
  const [partidaVirtual, setPartidaVirtual] = useState(false);
  const [sprintsJugados, setSprintsJugados] = useState([])
  const [selectedCanvas, setSelectedCanvas] = useState("canvasPostit")
  const arranqueListo = false

  // cartas y jugadas
  const [jugadas, setJugadas] = useState(null);
  const [cartas, setCartas] = useState([]);
  const [images, setImages] = useState([]);
  const [dimData, setDimData] = useState([]); // para chart digital innovation measurement
  const [ecosystem_val, setEcoSystemVal] = useState({}) // ECOSYSTEM
  const [sustainData, setSustainData] = useState({}) // SUSTAINABILITY

  // canvas
  const correctorGlobal = 95 // modificar aca si las banderas se salen de los recuadros
  const [tableroWidth, setTableroWidth] = useState(0);
  const [tableroHeight, setTableroHeight] = useState(0);
  const [tableroWidthZero, setTableroWidthZero] = useState(0);
  const [tableroHeightZero, setTableroHeightZero] = useState(0);
  const [droppedCardss, setDroppedCardss] = useState({
    1: [],
    2: [],
    3: [],
    4: [],
    5: [],
    6: [],
    7: [],
    8: [],
    9: []
  });

  // devuelve true si se jugo el sprint y falso si no
  function jugamosEsteSprint(keyword, data) {
    return data.some(item => item.nombre === keyword);
  }

  async function setearConfDesdeToken() {
    // recibir token de local storage
    const token = localStorage.getItem('token')
    console.log(`jugadas arranque setearConf ${JSON.stringify(jugadas)} tipo ${typeof jugadas}`)

    if (!token) { console.error(`no hay token!`); return null }
    console.log('Token found:', token);
    const claims = decodeToken(token);
    console.log('Token claims:', claims);

    // armar fecha
    const date = new Date();
    var now = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
    setFecha(now);
    console.log(`fecha es ${now}`);

    // armar idioma
    if (claims.idioma) {
      setIdioma(claims.idioma)
      console.log(`seteando path a ../assets/${claims.idioma}/cartas/...`)
      setPath(`../assets/${claims.idioma}/cartas/`)
      i18n.changeLanguage(claims.idioma)

      // setear fecha como string i18n
      now = date.toLocaleDateString(claims.idioma, {
        day: '2-digit',
        month: 'long',
        year: 'numeric',
      });
    }

    // setear datos usuario
    if (claims.nombre && claims.institucion) {
      setNombre(claims.nombre)
      setInstitucion(claims.institucion)
      console.log(`Generando final para ${claims.nombre}, ${claims.institucion}, el dia ${fecha}`)
    }

    // configuracion de juego
    if (claims.role === "Player" || claims.role === "Captain") {

      // setear partida y equipo
      setSelectedPartida(claims.partida)
      setSelectedEquipo(claims.equipo)
      console.log(`Seteando pagina final para partida ${claims.partida}, equipo ${claims.equipo}...`)

      // datos asociados al equipo
      var datosEquipos, datosEquipo, datosSprints, datosJugadas, datosMejora
      try {
        datosEquipos = await equiposEnPartida(claims.partida)
        datosEquipo = await fetchData(claims.partida, claims.equipo)
        datosSprints = await numeroSprintsEnPartida(claims.partida)
        datosJugadas = await verJugadasEquipo(claims.partida, claims.equipo)
        datosMejora = await getMejora(datosEquipo[0])

        const mejora = {
          total: Math.round((datosMejora.mejora_total - 1) * 1000, 0),
          porter: Math.round((datosMejora.mejora_porter) * 1000, 0), // agregamos un 0 hasta determinar pq es distinto del grafico
          amit: Math.round((datosMejora.mejora_amit) * 1000, 0)
        }


        //console.log(`datos equipo son ${JSON.stringify(datosEquipo)}`)
        console.log(`Equipo esta en sprint id ${datosEquipo[0]["sprint_id"]}`)
        console.log(`datos de todos los sprints en p son ${JSON.stringify(datosSprints)}`)
        console.log(`datos de todos las jugadas son ${JSON.stringify(datosJugadas)}`)
        console.log(`datos mejora son ${JSON.stringify(mejora)}`)

        // armar un array de nombres de sprints jugados
        const datosSprintsJugados = [...new Set(datosSprints.map(item => item.nombre))];
        setSprintsJugados(datosSprintsJugados);

        // ir alimentando a cada seccion
        console.log(`enviando a top3: ${JSON.stringify(datosEquipos)}`)
        // metricas se arma solo
        // blue ocean? lo encuentra

        // ecosystem
        var ecosystem
        const ecosystemResp = 1
        if (!ecosystemResp || !ecosystemResp.success) { console.error(`no hubo respuesta de ecosystemResp`) }
        else { ecosystem = ecosystemResp.data }
        setEcoSystemVal(ecosystem)

        // empezar a armar jugadas, DIM chart, Sustainability
        setJugadas(datosJugadas) // traer cartas jugadas
        console.log(`jugadas seteado ${JSON.stringify(jugadas)} tipo ${typeof jugadas}`)

        if (claims.presencial === 1) {
          // JUEGO PRESENCIAL


        } else {
          // JUEGO VIRTUAL
          setPartidaVirtual(true)

          // traer el framework basado en partida



          // setear img framework
          var selecciondeCanvas

          console.log(`canvas seleccionado es ${selecciondeCanvas}`)
          setSelectedCanvas(selecciondeCanvas)

          // traer recuadros canvas del framework



          // convierte a los recuadros en un array de IDs
          var recuadrosViejosArr = 1
          console.log(`recuadrosViejosArr es ${JSON.stringify(recuadrosViejosArr)}`)
          var recuadrosViejos = {}

          // si se jugo data driven virt, agregar recuadros de ese
          if (datosSprintsJugados.includes("DataDriven")) {
            // traer recuadros DD


            // 
            var recuadrosViejosArrDD = 1
            console.log(`recuadrosViejosArr data driven es ${JSON.stringify(recuadrosViejosArrDD)}`)

            // mergearlo al array
            recuadrosViejosArr = recuadrosViejosArr.concat(recuadrosViejosArr, recuadrosViejosArrDD)
            console.log(`recuadrosViejosArr con data driven es ${JSON.stringify(recuadrosViejosArrDD)}`)
          }

          // filtrar jugadas para los que tengan recuadro
          if (datosJugadas) {
            datosJugadas.filter(x => x.recuadro)
            console.log(`jugadas anteriores con recuadro son ${JSON.stringify(datosJugadas)}`)

            // traer todas las imagenes para las cartas jugadas previamente
            const arrayCartasViejas = datosJugadas.map(x => x.carta_id)
            console.log(`arrayCartasViejas es ${arrayCartasViejas}`)
            const cartasViejasData = await variasCartasPorID(arrayCartasViejas)
            console.log(`cartasViejasData es ${JSON.stringify(cartasViejasData)}`)



            // armar las imagenes de los sprints anteriores y ponerlos en el tablero
            // mapear los recuadros, sin que se puedan deseleccionar
            try {
              for (let i = 0; i < recuadrosViejosArr.length; i++) {
                var keyD = recuadrosViejosArr[i];
                var jugD = datosJugadas.filter(x => x.recuadrocanvas_id === keyD);

                // Convertir jugadas a imágenes
                console.log(`jugD viejo es ${JSON.stringify(jugD)}`);
                console.log(`cartasviejasdata es array?`, Array.isArray(cartasViejasData)); // Check if it logs true


                for (let i = 0; i < jugD.length; i++) {
                  var carta = cartasViejasData.find(x => x && x.id === jugD[i].carta_id);

                  if (carta) {
                    var svgpath = carta.svg_front;
                    var imgInfo = 1

                    if (imgInfo) {
                      var name = imgInfo.name;
                      var src = imgInfo.src;
                      jugD[i] = { name: name, src: src };
                    }
                  }
                }

                console.log(`jugD nuevo es ${JSON.stringify(jugD)}`);
                recuadrosViejos[keyD] = jugD;
              }

              console.log(`recuadrosViejos son ${JSON.stringify(recuadrosViejos)}`);
            } catch (error) {
              console.error(error);
            }

          } else {
            // si no hay jugadas anteriores, monta un array de objetos con arrays vacios
            for (let i = 0; i < recuadrosViejosArr.length; i++) {
              var keyD = recuadrosViejosArr[i]
              recuadrosViejos[keyD] = []
            }
          }

        }

      } catch (error) {
        console.error(`No se pudo traer datos en conf ini:`, error)
      }

    } else if (claims.role === "Admin") {
      //setUserType("Admin")
    } else {
      console.error("y vos quien sos ???")
    }

  }



  async function getMejora(e) {
    var m = e
    const resp = await fetchModifier(m.partida_id, m.id)
    if (!resp || !resp.success) {
      console.error(`getMejora: error trayendo equipo ${m.id}`)
    } else {
      m.mejora_total = resp.data.total;
      m.mejora_porter = resp.data.porter;
      m.mejora_amit = resp.data.amit;

      console.log(`top3mejora: equipo ${m.id} tiene mejora total ${JSON.stringify(m.mejora_total)}`)
    }
    return m
  }



  useEffect(() => {
    setearConfDesdeToken()
  }, [])

  function setearConfDesdeToken() {
    // recibir token de local storage
    const token = localStorage.getItem("token");

    if (token) {
      console.log("Token found:", token);
      const claims = decodeToken(token);
      console.log("Token claims:", claims);

      // armar idioma
      if (claims.idioma) {
        i18n.changeLanguage(claims.idioma);
      }

      // configuración de juego
      if (claims.role === "Player" || claims.role === "Captain") {
        setUserType("Player");
        setSelectedPartida(claims.partida);
        setSelectedEquipo(claims.equipo);

        // traerResultados(claims.partida, claims.equipo)
      } else if (claims.role === "Admin") {
        setUserType("Admin");
      } else {
        console.error("y vos quien sos ???");
      }
    }
  }

  const fetchPartidas = async () => {
    try {
      const response = await fetch(`https://${host}/api/partidas`);
      if (!response.ok) {
        throw new Error("Respuesta del servidor no es OK");
      }
      const data = await response.json();
      if (Array.isArray(data.data) && data.data.length) {
        setPartidas(data.data);
        setSelectedPartida(data.data[0].id); // Corregido para seleccionar el primer ID
      } else {
        console.error("Formato de datos no esperado:", data);
      }
    } catch (error) {
      console.error("Error al obtener los datos:", error);
    }
  };

  const fetchEquiposPorPartida = async (partidaId) => {
    try {
      const response = await fetch(`https://${host}/juego/${partidaId}`);
      if (!response.ok) {
        throw new Error("Respuesta del servidor no es OK");
      }
      const data = await response.json();
      if (Array.isArray(data.data)) {
        setEquipos(data.data);
      } else {
        setEquipos([]);
        console.warn(data.message);
      }
    } catch (error) {
      setEquipos([]); // En caso de un error, resetea los equipos.
      console.error("Error al obtener los equipos (o no los pediste todavía):", error);
    }
  };

  useEffect(() => {
    fetchPartidas();
  }, []);

  // Este useEffect se encargará de cargar los equipos cuando cambie la partida seleccionada
  useEffect(() => {
    if (selectedPartida) {
      fetchEquiposPorPartida(selectedPartida);
    }
  }, [selectedPartida]);

  // Use useEffect to fetch resultados data and set loading state
  useEffect(() => {
    resultados(selectedPartida, selectedEquipo)
      .then((result) => {
        console.log("Resultados traídos exitosamente:", result);
        var datosResultados = result;
        console.log(JSON.stringify(datosResultados));
        setdatosAñoActual(result.datosAñoActual);
        setdatosAñoActualE(result.datosAñoActualE);
        setTablaMetricas(result.TablaMetricas);
        settableData(result.tableData);
        console.log("Datos Año Actual:", result.datosAñoActual);
        console.log("Datos Año Actual Egresos:", result.datosAñoActualE);
        // Extraer el dato de RTDO NETO
        // Capturar el dato en la posición 20
        const rtdoNetoItem = result.datosAñoActualE[20];
        if (rtdoNetoItem) {
          setRtdoNeto(rtdoNetoItem.cantidad);
          console.log(`RTDO NETO encontrado: ${rtdoNetoItem.cantidad}`);
        } else {
          console.error("RTDO NETO no encontrado en la posición 20.");
        }

        setLoading(false); // Set loading to false once data is available
      })
      .catch((error) => {
        console.error("Error trayendo resultados:", error);
        setLoading(true); // Set loading to false in case of an error
      });
  }, [selectedPartida, selectedEquipo]);


  const milestones = [
    { min: 3, max: 5, value: 100000 },
    { min: 5, max: 8, value: 200000 },
    { min: 8, max: 11, value: 300000 },
    { min: 11, max: Infinity, value: 500000 }
  ];

  function getMilestoneBonus(quizzes) {
    const milestone = milestones.find(({ min, max }) => quizzes >= min && quizzes < max);
    return milestone ? milestone.value : 0;
  }

  return (
    <>
      <div
        className="content"
        style={{
          backgroundImage: `url(${img})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          height: "100vh",
          width: "100vw",
        }}
      >
        <>
          <Row>
            <Col xs="12">
              <Card style={{ backgroundColor: "#003366" }}>
                <CardBody>
                  <h1 style={{ color: "#E8E8E8" }}> Comparación de resultados </h1>
                  <Comparativa />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </>
      </div >
    </>
  );
}

export default ComparativaPage;