import React, { useState } from "react";

const ImageGallery = ({ images }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isFlipped, setIsFlipped] = useState(false);

  const openModal = (index) => {
    setCurrentIndex(index);
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
    setIsFlipped(false);
  };

  const toggleFlip = () => {
    setIsFlipped(!isFlipped);
  };

  return (
    <div style={{ display: "flex", gap: "16px", justifyContent: "center", flexWrap: "wrap" }}>
      {images.map((image, index) => (
        <div
          key={index}
          onClick={() => openModal(index)}
          style={{
            width: "300px", // Puedes ajustar el tamaño según tu contenedor
            height: "400px", // Ajusta esta altura para mantener la proporción
            overflow: "hidden",
            cursor: "pointer",
          }}
        >
          <img
            src={image.front}
            alt={`Image ${index}`}
            style={{
              width: "100%",
              height: "100%",
              objectFit: "contain" // Asegura que la imagen se adapte sin recortarse ni deformarse
            }}
          />
        </div>
      ))}

      {isOpen && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0, 0, 0, 0.75)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 50,
          }}
        >
          <div style={{ position: "relative", width: "75%", maxWidth: "800px", padding: "16px", backgroundColor: "#fff", borderRadius: "8px" }}>
            <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "16px" }}>
              <button
                onClick={toggleFlip}
                style={{
                  backgroundColor: "#3b82f6",
                  padding: "8px 16px",
                  color: "#fff",
                  borderRadius: "4px",
                  cursor: "pointer",
                }}
              >
                {isFlipped ? "Ver Frente" : "Ver Reverso"}
              </button>
              <button
                onClick={closeModal}
                style={{
                  backgroundColor: "#ef4444",
                  padding: "8px 16px",
                  color: "#fff",
                  borderRadius: "4px",
                  cursor: "pointer",
                }}
              >
                Cerrar
              </button>
            </div>
            <img
              src={isFlipped ? images[currentIndex].back : images[currentIndex].front}
              alt={`Image ${currentIndex}`}
              style={{ width: "100%", height: "auto", borderRadius: "8px" }}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default ImageGallery;