import React, { useState, useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Chart as ChartJS, LineElement, CategoryScale, LinearScale, PointElement } from 'chart.js';
import imagen1 from './88.png';
import imagen2 from './99.png';
import imagen3 from './33.png';
import imagen4 from './44.png';
import imagen5 from './55.png';
import imagen6 from './66.png';
import imagen7 from './77.png';
import carta1 from '../../assets/img/customer/133.png';
import carta2 from '../../assets/img/customer/134.png';
import carta3 from '../../assets/img/customer/135.png';
import carta4 from '../../assets/img/customer/136.png';
import carta5 from '../../assets/img/customer/137.png';
import carta6 from '../../assets/img/customer/138.png';
import carta7 from '../../assets/img/customer/139.png';
import carta8 from '../../assets/img/customer/140.png';
import carta9 from '../../assets/img/customer/141.png';
import { useIdiomaUsuario } from './idiomaHelper';

import Persona1 from '../../assets/img/customer/125.png';
import Persona2 from '../../assets/img/customer/127.png';
import Persona3 from '../../assets/img/customer/129.png';
import Persona4 from '../../assets/img/customer/131.png';

ChartJS.register(LineElement, CategoryScale, LinearScale, PointElement);

const sections = [
  'Descubrimiento', 'Investigacion', 'Consideracion',
  'Decision', 'Compra', 'Post Compra', 'Fidelizacion'
];

const initialImages = [
  { id: 1, src: carta1, points: 3 },
  { id: 2, src: carta2, points: 3 },
  { id: 3, src: carta3, points: 2 },
  { id: 4, src: carta4, points: 0.5 },
  { id: 5, src: carta5, points: 1 },
  { id: 6, src: carta6, points: 0.8 },
  { id: 7, src: carta7, points: 1 },
  { id: 8, src: carta8, points: 2 },
  { id: 9, src: carta9, points: -1 },
];

const sectionBackgrounds = [
  imagen1, imagen2, imagen3, imagen4, imagen5, imagen6, imagen7
];

const problemPersonData = {
  "Problema 1, Persona 3": [2, 3, 4, 1, 2, 3, 4],
  "Problema 2, Persona 2": [1, 2, 1, 3, 4, 2, 1],
  "Problema 3, Persona 1": [3, 2, 1, 4, 3, 2, 1],
  "Problema 4, Persona 4": [4, 3, 2, 1, 4, 3, 2]
};

const problemCards = {
  "Problema 1, Persona 3": Persona3,
  "Problema 2, Persona 2": Persona2,
  "Problema 3, Persona 1": Persona1,
  "Problema 4, Persona 4": Persona4
};

const problemTexts = {
  es: {
    "Problema 1, Persona 3":
      "Los clientes enfrentan dificultades en cada etapa del proceso de compra. " +
      "En la fase de descubrimiento, la propuesta de valor no es clara y los competidores desvían la atención. " +
      "Durante la investigación, la falta de reseñas y la desactualización de precios generan incertidumbre. " +
      "En la consideración, la ausencia de contenido educativo y la dificultad para encontrar políticas de garantía afectan la toma de decisiones. " +
      "La etapa de decisión se ve obstaculizada por problemas técnicos con descuentos y falta de asistencia en tiempo real. " +
      "Después de la compra, los clientes experimentan problemas con envíos, seguimiento de pedidos y contacto con el servicio al cliente. " +
      "Finalmente, en la fidelización, los cambios en programas de lealtad y dificultades para canjear recompensas crean frustración.",

    "Problema 2, Persona 2":
      "Los usuarios enfrentan obstáculos en cada paso del proceso de compra, afectando su confianza y experiencia. " +
      "En la fase de descubrimiento, el contenido no es atractivo y no capta la atención de los clientes, además de una falta de colaboraciones estratégicas. " +
      "Durante la investigación, los clientes tienen dificultades para comparar productos por falta de información clara y herramientas interactivas. " +
      "En la etapa de consideración, la desorganización en la presentación de productos y la falta de información sobre envíos dificultan la toma de decisiones. " +
      "Los problemas en la fase de decisión incluyen dificultades para encontrar información de contacto y poca claridad sobre el manejo de datos personales y de pago. " +
      "En la compra, la información de envío es confusa y hay dificultades para modificar pedidos después de la compra. " +
      "El post-compra se ve afectado por problemas en la instalación y falta de incentivos para recibir reseñas. " +
      "Por último, en fidelización, los clientes encuentran programas de lealtad más atractivos en la competencia y no cuentan con eventos exclusivos o contenido especial.",

    "Problema 3, Persona 1":
      "Los clientes enfrentan barreras en la experiencia de compra que generan desconfianza y afectan la conversión. " +
      "Durante la fase de descubrimiento, la publicidad no es efectiva y la falta de estrategias de marketing local reduce la atracción de clientes cercanos. " +
      "En la investigación, las opiniones mixtas de otros compradores generan dudas sobre la calidad del producto y la falta de detalles técnicos dificulta la decisión. " +
      "En la consideración, los largos tiempos de espera en tienda y las respuestas automatizadas en el chat impiden una atención personalizada. " +
      "En la fase de decisión, la falta de transparencia en los costos hasta el último paso del pago y la ausencia de información sobre tiempos de entrega afectan la confianza del usuario. " +
      "Durante la compra, los problemas de seguridad en el pago generan desconfianza y la discrepancia entre el stock en línea y la disponibilidad real de productos frustra a los clientes. " +
      "En el post-compra, la dificultad para acceder a facturas y recibos, junto con la ausencia de instrucciones claras de ensamblaje, crean inconvenientes. " +
      "Finalmente, en fidelización, la falta de un sistema de seguimiento y personalización en las ofertas reduce la participación de los clientes en programas de lealtad.",

    "Problema 4, Persona 4":
      "Los clientes experimentan dificultades en cada etapa del proceso de compra, lo que afecta la conversión y la retención. " +
      "En la fase de descubrimiento, la falta de presencia en redes sociales y la baja visibilidad en motores de búsqueda dificultan que los clientes potenciales encuentren el negocio. " +
      "Durante la investigación, la información confusa o incompleta en el sitio web y la dificultad para encontrar categorías específicas de productos complican la toma de decisiones. " +
      "En la etapa de consideración, la lentitud en la carga de las páginas y una interfaz poco intuitiva frustran a los clientes. " +
      "En la decisión, los problemas técnicos durante el pago y las limitadas opciones de pago afectan la finalización de la compra. " +
      "Durante la compra, las transacciones fallidas y errores en el cálculo de precios generan desconfianza. " +
      "En el post-compra, los tiempos de entrega más largos de lo prometido y la falta de seguimiento generan insatisfacción. " +
      "Finalmente, en fidelización, los clientes enfrentan dificultades con los sistemas de recompensas y la falta de comunicación sobre beneficios y promociones para miembros de programas de lealtad."
  },

  "en": {
    "Problem 1, Persona 3":
      "Customers face difficulties at every stage of the purchasing process. " +
      "In the discovery phase, the value proposition is unclear, and competitors divert attention. " +
      "During research, the lack of reviews and outdated prices create uncertainty. " +
      "In the consideration stage, the absence of educational content and difficulty finding warranty policies affect decision-making. " +
      "The decision stage is hindered by technical issues with discounts and lack of real-time assistance. " +
      "After the purchase, customers experience issues with shipping, order tracking, and customer service contact. " +
      "Finally, in loyalty, changes in loyalty programs and difficulties redeeming rewards create frustration.",

    "Problem 2, Persona 2":
      "Users face obstacles at every step of the purchasing process, affecting their confidence and experience. " +
      "In the discovery phase, the content is not engaging and fails to capture customers' attention, along with a lack of strategic partnerships. " +
      "During research, customers struggle to compare products due to unclear information and lack of interactive tools. " +
      "In the consideration stage, disorganized product presentation and lack of shipping information make decision-making difficult. " +
      "Problems in the decision phase include difficulties finding contact information and unclear handling of personal and payment data. " +
      "During the purchase, shipping information is confusing, and there are difficulties modifying orders after purchase. " +
      "Post-purchase issues include installation problems and lack of incentives for leaving reviews. " +
      "Finally, in loyalty, customers find more attractive loyalty programs from competitors and lack exclusive events or special content.",

    "Problem 3, Persona 1":
      "Customers face barriers in the shopping experience that create distrust and affect conversion. " +
      "During the discovery phase, ineffective advertising and lack of local marketing strategies reduce customer attraction. " +
      "In research, mixed reviews from other buyers create doubts about product quality, and the lack of technical details makes decision-making difficult. " +
      "In the consideration stage, long waiting times in stores and automated chat responses prevent personalized service. " +
      "In the decision phase, lack of transparency in costs until the final payment step and absence of delivery time information affect user confidence. " +
      "During the purchase, security issues in payment create distrust, and discrepancies between online stock and actual product availability frustrate customers. " +
      "In the post-purchase phase, difficulties accessing invoices and receipts, along with the absence of clear assembly instructions, create inconveniences. " +
      "Finally, in loyalty, the lack of a tracking system and personalization in offers reduces customer participation in loyalty programs.",

    "Problem 4, Persona 4":
      "Customers experience difficulties at every stage of the purchasing process, affecting conversion and retention. " +
      "In the discovery phase, lack of social media presence and low visibility in search engines make it difficult for potential customers to find the business. " +
      "During research, unclear or incomplete website information and difficulty finding specific product categories complicate decision-making. " +
      "In the consideration stage, slow page loading and an unintuitive interface frustrate customers. " +
      "In the decision phase, technical problems during payment and limited payment options affect purchase completion. " +
      "During the purchase, failed transactions and pricing calculation errors create distrust. " +
      "In the post-purchase phase, longer-than-promised delivery times and lack of tracking cause dissatisfaction. " +
      "Finally, in loyalty, customers face difficulties with reward systems and lack of communication about benefits and promotions for loyalty program members."
  },

  "pt": {
    "Problema 1, Pessoa 3":
      "Os clientes enfrentam dificuldades em cada etapa do processo de compra. " +
      "Na fase de descoberta, a proposta de valor não é clara e os concorrentes desviam a atenção. " +
      "Durante a pesquisa, a falta de avaliações e preços desatualizados geram incerteza. " +
      "Na consideração, a ausência de conteúdo educativo e a dificuldade em encontrar políticas de garantia afetam a tomada de decisões. " +
      "A etapa de decisão é dificultada por problemas técnicos com descontos e falta de assistência em tempo real. " +
      "Após a compra, os clientes enfrentam problemas com envios, rastreamento de pedidos e contato com o serviço de atendimento ao cliente. " +
      "Por fim, na fidelização, mudanças nos programas de lealdade e dificuldades para resgatar recompensas geram frustração.",

    "Problema 2, Pessoa 2":
      "Os usuários enfrentam obstáculos em cada etapa do processo de compra, afetando sua confiança e experiência. " +
      "Na fase de descoberta, o conteúdo não é atrativo e não capta a atenção dos clientes, além da falta de parcerias estratégicas. " +
      "Durante a pesquisa, os clientes têm dificuldades para comparar produtos devido à falta de informações claras e ferramentas interativas. " +
      "Na fase de consideração, a desorganização na apresentação dos produtos e a falta de informações sobre envios dificultam a tomada de decisões. " +
      "Os problemas na fase de decisão incluem dificuldades para encontrar informações de contato e pouca clareza sobre o gerenciamento de dados pessoais e de pagamento. " +
      "Na compra, as informações de envio são confusas e há dificuldades para modificar pedidos após a compra. " +
      "O pós-compra é afetado por problemas na instalação e falta de incentivos para deixar avaliações. " +
      "Por fim, na fidelização, os clientes encontram programas de lealdade mais atraentes na concorrência e não têm acesso a eventos exclusivos ou conteúdo especial.",

    "Problema 3, Pessoa 1":
      "Os clientes enfrentam barreiras na experiência de compra que geram desconfiança e afetam a conversão. " +
      "Durante a fase de descoberta, a publicidade não é eficaz e a falta de estratégias de marketing local reduz a atração de clientes próximos. " +
      "Na pesquisa, avaliações mistas de outros compradores geram dúvidas sobre a qualidade do produto, e a falta de detalhes técnicos dificulta a decisão. " +
      "Na consideração, os longos tempos de espera na loja e as respostas automatizadas no chat impedem um atendimento personalizado. " +
      "Na fase de decisão, a falta de transparência nos custos até a última etapa do pagamento e a ausência de informações sobre prazos de entrega afetam a confiança do usuário. " +
      "Durante a compra, problemas de segurança no pagamento geram desconfiança e a discrepância entre o estoque online e a disponibilidade real dos produtos frustra os clientes. " +
      "No pós-compra, a dificuldade para acessar faturas e recibos, junto com a ausência de instruções claras de montagem, criam inconvenientes. " +
      "Por fim, na fidelização, a falta de um sistema de rastreamento e personalização nas ofertas reduz a participação dos clientes nos programas de lealdade.",

    "Problema 4, Pessoa 4":
      "Os clientes enfrentam dificuldades em cada etapa do processo de compra, afetando a conversão e a retenção. " +
      "Na fase de descoberta, a falta de presença nas redes sociais e a baixa visibilidade nos motores de busca dificultam que clientes em potencial encontrem o negócio. " +
      "Durante a pesquisa, informações confusas ou incompletas no site e a dificuldade em encontrar categorias específicas de produtos complicam a tomada de decisões. " +
      "Na consideração, a lentidão no carregamento das páginas e uma interface pouco intuitiva frustram os clientes. " +
      "Na decisão, problemas técnicos durante o pagamento e opções limitadas de pagamento afetam a finalização da compra. " +
      "Durante a compra, transações com falhas e erros no cálculo de preços geram desconfiança. " +
      "No pós-compra, prazos de entrega mais longos do que o prometido e a falta de rastreamento geram insatisfação. " +
      "Por fim, na fidelização, os clientes enfrentam dificuldades com os sistemas de recompensas e a falta de comunicação sobre benefícios e promoções para membros dos programas de lealdade."
  }
};

const Section = ({ title, backgroundImage, children, onDrop }) => {
  const [{ isOver }, drop] = useDrop(() => ({
    accept: 'IMAGE',
    drop: (item) => {
      onDrop(item, title);
      return { name: title };
    },
    collect: (monitor) => ({
      isOver: monitor.isOver()
    })
  }), [title]);

  return (
    <div style={{
      flex: '0 0 150px',
      height: '300px',
      padding: '10px',
      margin: '0 5px',
      border: '2px dashed #ccc',
      borderRadius: '8px',
      textAlign: 'center',
      backgroundImage: `url(${backgroundImage})`,
      backgroundSize: '100% 100%',
      backgroundPosition: 'center',
      backgroundColor: isOver ? '#f0f8ff' : 'white',
      color: 'black',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'flex-start'
    }} ref={drop}>
      <h3 style={{ color: 'black' }}>{title}</h3>
      {children}
    </div>
  );
};

const ImageBox = ({ id, src, points }) => {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: 'IMAGE',
    item: { id, src, points },
    collect: (monitor) => ({
      isDragging: monitor.isDragging()
    })
  }), [id, src, points]);

  return (
    <div style={{
      margin: '5px',
      backgroundColor: '#87cefa',
      border: '1px solid #4682b4',
      borderRadius: '4px',
      cursor: 'move',
      opacity: isDragging ? 0.5 : 1,
      color: 'black'
    }} ref={drag}>
      <img src={src} alt={`Imagen ${id}`} style={{ width: '100px', height: '133px' }} />
    </div>
  );
};

const Customersprint = () => {
  const idioma = useIdiomaUsuario();
  const [path, setPath] = useState(`../assets/${idioma}/cartas/`);

  const [selectedOption, setSelectedOption] = useState('');
  const [images] = useState(initialImages);
  const [droppedImages, setDroppedImages] = useState(sections.map(() => []));
  const [baseValues, setBaseValues] = useState([]);
  const [showImprovement, setShowImprovement] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  console.log('idioma:', idioma);

  useEffect(() => {
    setPath(`../assets/${idioma}/cartas/`);
  }, [idioma]);

  useEffect(() => {
    // Asignar un problema al azar al cargar el componente
    const randomProblem = Object.keys(problemPersonData)[Math.floor(Math.random() * 4)];
    setSelectedOption(randomProblem);
  }, []);

  useEffect(() => {
    if (selectedOption) {
      setBaseValues(problemPersonData[selectedOption]);
    } else {
      setBaseValues([]);
    }
  }, [selectedOption]);

  const handleDrop = (item, section) => {
    const sectionIndex = sections.indexOf(section);
    if (sectionIndex !== -1) {
      setDroppedImages((prev) => {
        const newDroppedImages = [...prev];
        newDroppedImages[sectionIndex] = [...newDroppedImages[sectionIndex], item];
        return newDroppedImages;
      });
    }
  };

  const modData = sections.map((_, i) => {
    const baseValue = baseValues[i] || 1;
    const additionalPoints = droppedImages[i].reduce((acc, img) => acc + img.points, 0);
    return Math.min(Math.max(baseValue + additionalPoints, 1), 5);
  });

  const lineData = {
    labels: sections,
    datasets: [
      ...(showImprovement
        ? [
          {
            label: 'Mejora',
            data: modData,
            fill: false,
            backgroundColor: 'rgba(75,192,192,0.4)',
            borderColor: 'rgba(75,192,192,1)',
          },
        ]
        : []),
      {
        label: 'Situación inicial',
        data: baseValues,
        fill: false,
        backgroundColor: 'rgba(255,99,132,0.4)',
        borderColor: 'rgba(255,99,132,1)'
      }
    ]
  };

  const lineOptions = {
    scales: {
      y: {
        beginAtZero: false,
        min: 1,
        max: 5,
        ticks: {
          display: false
        },
        grid: {
          color: 'rgba(255, 255, 255, 0.2)'
        }
      },
      x: {
        ticks: {
          color: 'white',
          font: {
            size: 25
          }
        },
        grid: {
          color: 'rgba(255, 255, 255, 0.2)'
        }
      }
    },
    plugins: {
      legend: {
        labels: {
          color: 'white',
          font: {
            size: 25
          }
        }
      }
    }
  };


  useEffect(() => {
    const savedMove = localStorage.getItem('savedMove');
    if (savedMove) {
      setShowImprovement(true);
      setIsButtonDisabled(true);
    }
  }, []);

  const handleSendMove = () => {
    setShowImprovement(true);
    setIsButtonDisabled(true);

    const gameData = {
      problem: selectedOption,
      improvements: droppedImages
    };

    localStorage.setItem('savedMove', JSON.stringify(gameData));
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <div style={{ fontFamily: 'Arial, sans-serif', padding: '20px' }}>
        <div style={{ fontFamily: 'Arial, sans-serif', padding: '20px' }}>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '15px' }}>
            <div style={{ maxWidth: '600px', textAlign: 'left' }}>
              <h2 style={{ textAlign: 'center' }}>Problema:</h2>
              <ul style={{ fontSize: '1.2rem', fontWeight: 'bold', paddingLeft: '20px', lineHeight: '1.6' }}>
                {(problemTexts[idioma]?.[selectedOption] ? problemTexts[idioma][selectedOption].split(". ") : ["Seleccionando problema..."]).map((line, index) => (
                  <li key={index}>{line}.</li>
                ))}
              </ul>
            </div>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '20px' }}>
              <div>
                {selectedOption && <img src={problemCards[selectedOption]} alt="Carta del problema" style={{ width: '400px', height: '550px' }} />}
              </div>

              <button
                onClick={handleSendMove}
                disabled={isButtonDisabled}
                style={{
                  fontSize: '1rem',
                  padding: '15px 20px',
                  color: 'white',
                  fontWeight: 'bold',
                  backgroundColor: isButtonDisabled ? '#555' : '#000080',
                  border: 'none',
                  borderRadius: '5px',
                  cursor: isButtonDisabled ? 'not-allowed' : 'pointer',
                  minWidth: '150px',
                  textAlign: 'center'
                }}
              >
                {isButtonDisabled ? 'Jugada enviada' : 'Enviar jugada'}
              </button>
            </div>
          </div>
        </div>
        <div style={{ display: 'flex', marginBottom: '10px', flexWrap: 'wrap', color: 'black' }}>
          {sections.map((section, index) => (
            <Section key={section} backgroundImage={sectionBackgrounds[index]} onDrop={(item) => handleDrop(item, section)}>
              {droppedImages[index].map(image => (
                <ImageBox key={image.id} {...image} />
              ))}
            </Section>
          ))}
        </div>
        <div style={{ marginBottom: '20px' }}>
          <h3 style={{ color: 'white' }}>Cartas</h3>
          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            {images.map((image) => (
              <ImageBox key={image.id} {...image} />
            ))}
          </div>
        </div>
        <div style={{ position: 'relative' }}>
          <Line data={lineData} options={lineOptions} />
          <div style={{ position: 'absolute', top: '55%', transform: 'translateY(-55%)', paddingLeft: '100px' }}>
            <div style={{ fontSize: '25px', marginBottom: '65px' }}>😃</div>
            <div style={{ fontSize: '25px', marginBottom: '65px' }}>😊</div>
            <div style={{ fontSize: '25px', marginBottom: '65px' }}>😐</div>
            <div style={{ fontSize: '25px', marginBottom: '65px' }}>😞</div>
            <div style={{ fontSize: '25px', marginBottom: '65px' }}>😢</div>
          </div>
        </div>
      </div>
    </DndProvider>
  );
};

export default Customersprint;